/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { NgFlashMessageService } from 'ng-flash-messages';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { checkForAPIFailResponse } from '../../assets/js/utils';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  projects: any;
  assignAgents: any;
  link: any;
  agents: any;
  primarycolor: any;
  secondarycolor: any;
  selectedProject: any;
  company: any;
  agentname: any; // Selected agent name in the select box
  projectHeading: any;
  displayProjects: boolean = true;
  displayAgents: boolean = true;
  proname: string;       // Add project Field name
  url: string;           // Add project Field name
  agentName: string;     // Add agent Field name
  agentPassword: string; // Add agent Field name
  companyName: string;
  removingProject: boolean = false;
  output;
  id;
  username;
  agentDescription;
  agent_email;
  email;
  showAddProjectModel :boolean = false;
  showEditProjectModal :boolean = false;
  showAddAgentModel :boolean = false;
  listDivClass : string = 'col-md-12';
  showDetailDiv : boolean = false;
  /*Edit project props*/
  editProjectName : string;
  editProjectUrl : string;
  /*End*/

  constructor(
    private api: ApiService,
    private router: Router,
    private ngFlashMessageService: NgFlashMessageService
  ) { }

  ngOnInit() {
    this.projectHeading = "Project Detail"
    this.primarycolor = '#199699'
    this.secondarycolor = '#50cdd0'
    let id = localStorage.getItem('user')

    this.api.getDashboard().subscribe(res => {
      let httpSuccess = checkForAPIFailResponse(res);
      if(httpSuccess){
        this.company = res.user;
        this.companyName = this.company.username

        this.api.getProjects(id).subscribe(res => {
          let httpSuccess = checkForAPIFailResponse(res);
          if(httpSuccess){
            this.projects = res;
          }
        }, err => {
          console.log(err);
        });

        this.api.Agent(id).subscribe(res => {
          let httpSuccess = checkForAPIFailResponse(res);
          if(httpSuccess){
            this.agents = res;
          }
        }, err => {
          console.log(err);
        });

        // setting company name/admin name to localstorage
        localStorage.setItem("company_name",this.companyName);
      }
    },
      err => {
        console.log(err);
        this.api.logout();
        this.router.navigate(['/login']);
        return false;
      });
  }

  updateColors() {
    if (this.selectedProject) {
      let data = {
        project_id: this.selectedProject._id,
        primarycolor: this.primarycolor,
        secondarycolor: this.secondarycolor
      }
      this.api.updateColorsAdmin(data).subscribe((data) => {
        let httpSuccess = checkForAPIFailResponse(data);
        if(httpSuccess){
          if (data.success) {
            var messages = ['Colors updated successfuly']
            this.ngFlashMessageService.showFlashMessage({     //Show the message on the front end
              messages: messages,
              dismissible: true,
              timeout: 3000,
              type: 'success'
            });
          }
        }
      })
    } else {
      let messages = ['Please choose any project first to update color']
      this.ngFlashMessageService.showFlashMessage({     //Show the message on the front end
        messages: messages,
        dismissible: true,
        timeout: 5000,
        type: 'danger'
      });
    }
  }

  getProjectDetail(element: any) {
    if (!this.removingProject) {
      this.listDivClass = 'col-lg-7 col-md-7';
      this.showDetailDiv = true;

      this.selectedProject = element
      this.projectHeading = element.name
      this.api.getAgent(element._id).subscribe(data => {
        let httpSuccess = checkForAPIFailResponse(data);
        if(httpSuccess){
          this.assignAgents = data;
        }
      });

      this.api.getProjectDetail(element._id).subscribe(data => {
        let httpSuccess = checkForAPIFailResponse(data);
        if(httpSuccess){
          this.primarycolor = data.primarycolor
          this.secondarycolor = data.secondarycolor
        }
      })
      this.link = `<script id='vizz-chat' data-user="` + this.id + `" data-username="` + this.username + `" data-useremail="` + this.email + `" data-projectname="` + element.name + `" data-siteid="` + element._id + `" src="https://chat.updatemedaily.com/assets/cdn_vizz_livechat.js"></script>`
    }
  }

  assign() {
    if (this.selectedProject) {
      let data = {
        name: this.agentname
      }
      this.api.assign(data, this.selectedProject._id).subscribe((response) => {
        var messages = [response.message]
        if (response.success) {
          this.assignAgents.push(this.agents.find(x => x._id == this.agentname))
          this.ngFlashMessageService.showFlashMessage({
            messages: messages,
            dismissible: true,
            timeout: 5000,
            type: 'success'
          });
        }
        else{
          this.ngFlashMessageService.showFlashMessage({
            messages: messages,
            dismissible: true,
            timeout: 5000,
            type: 'danger'
          });
        }
      });
    } else {
      let messages = ['Please click on any project from current projects list to assign agent']
      this.ngFlashMessageService.showFlashMessage({     //Show the message on the front end
        messages: messages,
        dismissible: true,
        timeout: 5000,
        type: 'danger'
      });
    }
  }

  addproject() {
    let data = {
      proname: this.proname,
      url: this.url
    }
    this.showAddProjectModel = false;

    this.api.addproject(data, this.company._id).subscribe((status) => {
      let httpSuccess = checkForAPIFailResponse(status);
      if(httpSuccess){
        let messages = [status.message]
        if (status.success) {
          this.projects.push(status.data)
          this.proname = null
          this.url = null
          this.ngFlashMessageService.showFlashMessage({
            messages: messages,
            dismissible: true,
            timeout: 5000,
            type: 'success'
          });
        } else {
          this.ngFlashMessageService.showFlashMessage({
            messages: messages,
            dismissible: true,
            timeout: 4000,
            type: 'danger'
          });
        }
      }
    });
  }

  showEditProjectModalWindow(selectedProject) {
    this.selectedProject = selectedProject;
    this.editProjectName = this.selectedProject.name;
    this.editProjectUrl = this.selectedProject.url;
    this.showEditProjectModal = true;
  }

  editproject() {
    let data = {
      id : this.selectedProject._id,
      name: this.editProjectName,
      url: this.editProjectUrl
    }

    this.api.editproject(data, this.selectedProject._id).subscribe((status) => {
      let httpSuccess = checkForAPIFailResponse(status);
      if(httpSuccess){
        let messages = [status.message]
        if (status.success) {
          this.showEditProjectModal = false;
          this.ngFlashMessageService.showFlashMessage({
            messages: messages,
            dismissible: true,
            timeout: 5000,
            type: 'success'
          });

          this.selectedProject.name = this.editProjectName
          this.selectedProject.url = this.editProjectUrl
        }
      }
    });
  }

  removeProject(id) {
    this.removingProject = true // Make true inorder to not fire getProjectDetail() function
    this.api.removeproject({ id: id }).subscribe((res) => {
      let httpSuccess = checkForAPIFailResponse(res);
      if(httpSuccess){
        let messages = [res.message]
        if (res.success) {
          this.projects = this.projects.filter(x => x._id != id)
          this.assignAgents = null
          this.primarycolor = '#199699'
          this.secondarycolor = '#50cdd0'
          this.link = null
          this.removingProject = false // Make true inorder to not fire getProjectDetail() function
          this.ngFlashMessageService.showFlashMessage({
            messages: messages,
            dismissible: true,
            timeout: 5000,
            type: 'success'
          });
        }
      }
    })
  }

  addagent() {
    let data = {
      name: this.agentName,
      password: this.agentPassword,
      description:this.agentDescription,
      agent_email:this.agent_email
    }
    this.api.addagent(data, this.company._id).subscribe((response) => {
      let httpSuccess = checkForAPIFailResponse(response);
      if(httpSuccess){
        this.showAddAgentModel = false;
        let messages = [response.message]

        if (response.success) {
          this.agents.push(response.data)
          this.agentName = null
          this.agentPassword = null
          this.agent_email = null
          this.agentDescription = null
          this.ngFlashMessageService.showFlashMessage({
            messages: messages,
            dismissible: true,
            timeout: 5000,
            type: 'success'
          });
        } else {
          this.ngFlashMessageService.showFlashMessage({
            messages: messages,
            dismissible: true,
            timeout: 5000,
            type: 'danger'
          });
        }
      }
    })
  }

  removeAgent(id) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.api.removeagent({ id: id }).subscribe((res) => {
          let httpSuccess = checkForAPIFailResponse(res);
          if(httpSuccess){
            let messages = [res.message]
            if (res.success) {
              if (this.assignAgents) {
                this.assignAgents = this.assignAgents.filter(x => x._id != id);
              }
              this.agents = this.agents.filter(x => x._id != id);
              this.ngFlashMessageService.showFlashMessage({
                messages: messages,
                dismissible: true,
                timeout: 5000,
                type: 'success'
              });
            }
          }
        })
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) { }
    })
  }

  toggleProjects() {
    if (this.displayProjects) {
      this.displayProjects = false;
    } else {
      this.displayProjects = true;
    }
  }

  toggleAgents() {
    if (this.displayAgents) {
      this.displayAgents = false;
    } else {
      this.displayAgents = true;
    }
  }

  canDeactivate() {
    console.log("canDeactivate called...");
  }

  unAssignAgentFromProject(agentObject) {
    this.api.unassignAgent({ projectId: this.selectedProject._id, agentId: agentObject._id }).subscribe((data) => {
      if(data.hasOwnProperty('success') && data.success==true){
        this.assignAgents.forEach((e,i)=>{
          if(e._id==agentObject._id){
            this.assignAgents.splice(i,1);
          }
        })
        this.ngFlashMessageService.showFlashMessage({
          messages: [data.message],
          dismissible: true,
          timeout: 5000,
          type: 'success'
        });
      }
      else if(data.hasOwnProperty('message')) {
        this.ngFlashMessageService.showFlashMessage({     //Show the message on the front end
          messages: [data.message],
          dismissible: true,
          timeout: 5000,
          type: 'danger'
        });
      }
      else {
        let message = 'something not fine...';
        this.ngFlashMessageService.showFlashMessage({
          messages: [message],
          dismissible: true,
          timeout: 5000,
          type: 'danger'
        });
      }
    });
  }

}

