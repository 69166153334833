import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { checkForAPIFailResponse } from '../../assets/js/utils';
import { Router } from '@angular/router';
import { NgFlashMessageService } from 'ng-flash-messages';
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  selector: 'app-admin-googleauth',
  templateUrl: './admin-googleauth.component.html',
  styleUrls: ['./admin-googleauth.component.css']
})
export class AdminGoogleauthComponent implements OnInit {
  tfa: any = {};
  authcode: string = "";
  errorMessage: string = null;
  messages = [];
  data: any;
  dataURL: any;
  id;
  constructor(  private api: ApiService,
    private router: Router,
    private ngFlashMessageService: NgFlashMessageService,   
    ) {
  //  this.getAuthDetails();
  }
  // constructor() { }

  // ngOnInit() {
  // } 
  ngOnInit() {
    let adminId = (localStorage.getItem('user_role')=='agent')?localStorage.getItem('company_Id'):localStorage.getItem('user');
    this.setLoginPageDesign();
    this.api.getAdminGoogleAuth(adminId).subscribe(res => {
      let httpSuccess = checkForAPIFailResponse(res);
      if(httpSuccess){
        this.tfa = res;   
        localStorage.setItem("secret",res.tempSecret);
      }
    },
      err => {
        console.log(err);
        this.api.logout();
        this.router.navigate(['/login']);
        return false;
      });
  }
  confirm() {
    var token = {
      token: this.authcode,
      adminId: (localStorage.getItem('user_role')=='agent')?localStorage.getItem('company_Id'):localStorage.getItem('user')
    }
    this.api.verifyAdminAuth(token).subscribe((data) => {
    this.messages[0] = data.message
      if (data.success) {
          //  this.api.storeAgentData(data.token, data.user.name, data.user.id, data.user.user_role,data);
            this.ngFlashMessageService.showFlashMessage({
              messages: this.messages,
              dismissible: true,
              timeout: 3000,
              type: 'success'
            });
          this.router.navigate(['/dashboard']);
          this.unsetLoginPageDesign();
          } else {
            Swal.fire({
              title: 'Login Failed',
              text: this.messages[0] || 'Something not fine',
              icon: 'error'
            })
          }
        });
  }
  setLoginPageDesign() {
    document.body.style.backgroundRepeat  = "no-repeat";
    document.body.style.backgroundImage = "linear-gradient(#b8dbdc, #3cb9bc)";
    document.body.style.height = "100%";
    document.getElementById('main-html-tag').style.height = "100%";
 
  }
  unsetLoginPageDesign() {
    document.body.style.backgroundRepeat  = "unset";
    document.body.style.backgroundImage = "unset";
    document.body.style.height = "unset";
    document.getElementById('main-html-tag').style.height = "unset";
  }

}

