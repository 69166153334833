import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ChatService } from '../chat.service';
import $ = require('jquery');


@Component({
  selector: 'app-chatboxmessage',
  templateUrl: './chatboxmessage.component.html',
  styleUrls: ['./chatboxmessage.component.css']
})
export class ChatboxmessageComponent implements OnInit,AfterViewInit {
  message: any;
  messageClass: any;
  public selfRef: ChatboxmessageComponent;
  messages: string[] = [];
  user: any;
  Id;
  newmessage;
  newtime;
  messageColor: string;
  messageTextColor: string = '';
  updatemessage: any
  currentId;
  time
  constructor(
    private chatService: ChatService,
  ) { }

  ngOnInit() {
    if(this.updatemessage){
      if (this.updatemessage.sender == 'Agent') {
        this.messageClass = "Mymsg"
        this.messageColor = ''
      } else {
        this.messageClass = "Ymsg"
      }
      return;
    }
    else {
      if (this.message.sender == 'Agent') {
        this.messageClass = "Mymsg"
        this.messageColor = ''
      } else {
        this.messageClass = "Ymsg"
      }
    }
  }

  ngAfterViewInit(){
    if(this.updatemessage && this.updatemessage.message && (typeof this.updatemessage.message!='undefined'||typeof this.updatemessage.time!='undefined')){
      this.newmessage=this.updatemessage.message
      this.newtime=this.updatemessage.time
      let date= new Date(this.newtime)
      let getHours=date.getHours();
      let getMinutes=date.getMinutes();
      let seconds=date.getSeconds();

      let _getHours = getHours<10?'0'+getHours:getHours;
      let _getMinutes = getMinutes<10?'0'+getMinutes:getMinutes;
      let _seconds = seconds<10?'0'+seconds:seconds;

      $("#time_"+_getHours+_getMinutes+_seconds).text(this.newtime);
      $("#message_"+_getHours+_getMinutes+_seconds).text(this.newmessage);
      $("#edit_badge_"+_getHours+_getMinutes+_seconds).show();
    }
  }

  getPlainMessageHtml(messageText){
    return this.urlify(messageText)
  }

  urlify(text) {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    text = text.replace(urlRegex, function(url) {
      return '<a href="' + url + '" target="_blank" style="color: darkslategrey !important;">' + url + '</a>';
    })
    return text
  }
}
