import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
//import { MatButtonModule, MatToolbarModule, MatPaginatorModule, MatProgressSpinnerModule, MatSortModule, MatTableModule, MatIconModule, MatCardModule, MatFormFieldModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';


import { AppComponent } from './app.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './auth.guard';
import { AgentGuard } from './agent.guard';
import { ApiService } from './api.service';
import { AgentLoginComponent } from './agent-login/agent-login.component';
import { AgentGoogleauthComponent } from './agent-googleauth/agent-googleauth.component';
import { NgFlashMessagesModule } from 'ng-flash-messages';
import { ChatService } from './chat.service';
import { HttpModule } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import {ChatbotService } from './chatbot.service';
import { ProjPageComponent } from './proj-page/proj-page.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { ChatsComponent } from './chats/chats.component';
import { CustomersComponent } from './customers/customers.component';
import { CustomerComponent } from './customer/customer.component';
import { MessageComponent } from './message/message.component';
import { MessagesComponent } from './messages/messages.component';
import { ChatboxmessageComponent } from './chatboxmessage/chatboxmessage.component';
import { CookieService } from 'ngx-cookie-service';
import { CustomerEmptyComponent } from './customer-empty/customer-empty.component';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { ArchivesComponent } from './archives/archives.component';
import { VisitorsComponent } from './visitors/visitors.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ChatboxPrototypeComponent } from './chatbox-prototype/chatbox-prototype.component';
import { ClickOutsideDirective } from './click-outside.directive';
import { AssignedProjectsComponent } from './assigned-projects/assigned-projects.component';
import { TicketsComponent } from './tickets/tickets.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AgentPipe } from './agent.pipe';
import { DatePipe } from './date.pipe';
import { CountryPipe } from './country.pipe';
import { AgentListComponent } from './agent-list/agent-list.component';
import { AgentHistoryPipe } from './agent-history.pipe';
import { SafeHtmlPipe } from './safeHtml.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { ReportComponent } from './report/report.component';
import { TimeblinkerService } from './timeblinker.service';
import { ServicesService } from './services.service';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AgentProfileComponent } from './agent-profile/agent-profile.component';
import { HelpComponent } from './help/help.component';
import { CompanyDetailComponent } from './company-detail/company-detail.component';
import { ChatTutorialComponent } from './chat-tutorial/chat-tutorial.component';
import { BackupComponent } from './backup/backup.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AgentGraphComponent } from './agent-graph/agent-graph.component';
import { AgentAttendanceComponent } from './agent-attendance/agent-attendance.component';
import { AdminGoogleauthComponent } from './admin-googleauth/admin-googleauth.component';
import { AgentTimegraphComponent } from './agent-timegraph/agent-timegraph.component';
import {ChartModule} from 'angular2-chartjs';
import { AgentChatgraphComponent } from './agent-chatgraph/agent-chatgraph.component';

declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}

const AppRoutes: Routes = [
  { path: "register", component: RegisterComponent },
  { path: "login", component: LoginComponent },
  { path: "dashboard", component: DashboardComponent, canActivate: [AuthGuard] },
  { path: "agent-login", component: AgentLoginComponent },
  { path:"agent-googleauth", component:AgentGoogleauthComponent},
  { path:"admin-googleauth", component:AdminGoogleauthComponent},
  { path: "agent-chats",component: ChatsComponent, canActivate: [AgentGuard]},
  { path: "agent-dashboard", component: ChatsComponent, canActivate: [AgentGuard] },
  { path: "rejectsChat", component: ChatsComponent, canActivate: [AgentGuard] },
  { path: "proj-page/:project_name/:project_Id/:username/:useremail", component: ProjPageComponent },
  { path: "proj-page/:project_name/:project_Id/:username/:useremail/:userstep", component: ProjPageComponent },
  { path: "assigned-projects", component: AssignedProjectsComponent },
  { path: "history", component: ArchivesComponent }, //
  { path: "visitors", component: VisitorsComponent  },
  { path: "tickets", component: TicketsComponent   },
  { path: "agents", component:  AgentListComponent }, //
  { path: "reports", component: ReportComponent }, //
  { path: "timelogs", component: AgentAttendanceComponent},
  { path:"graphs", component:AgentGraphComponent},
  { path:"timegraphs", component:AgentTimegraphComponent},
  {path:"chatgraphs", component:AgentChatgraphComponent},
  { path: "apps", component: LandingPageComponent },
  { path: "subscribe", component: LandingPageComponent },
  { path: "notifications", component: LandingPageComponent },
  { path: "help", component:HelpComponent},
  { path: "company-detail", component:CompanyDetailComponent},
  { path: "chat-tutorial", component:ChatTutorialComponent},
  { path: "agent_profile",component:AgentProfileComponent,canActivate: [AgentGuard] },
  { path: "proj-page/:project_name/:project_Id", component: ProjPageComponent },
  { path:"backup",component:BackupComponent},
  { path: 'not-found', component: NotFoundComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: '**', redirectTo: "/not-found"},
  // {
  //   path:"**",
  //   // pathMatch   : 'full',
  //   component:NotFoundComponent
  // }
];

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    LoginComponent,
    DashboardComponent,
    AgentLoginComponent,
    ProjPageComponent,
    HeaderComponent,
    ChatsComponent,
    CustomersComponent,
    CustomerComponent,
    MessageComponent,
    MessagesComponent,
    ChatboxmessageComponent,
    CustomerEmptyComponent,
    ArchivesComponent,
    VisitorsComponent,
    ChatboxPrototypeComponent,
    ClickOutsideDirective,
    AssignedProjectsComponent,
    TicketsComponent,
    LandingPageComponent,
    AgentPipe,
    DatePipe,
    CountryPipe,
    AgentListComponent,
    AgentHistoryPipe,
    ReportComponent,
    UserProfileComponent,
    AgentProfileComponent,
    HelpComponent,
    CompanyDetailComponent,
    ChatTutorialComponent,
    BackupComponent,
    NotFoundComponent,
    SafeHtmlPipe,
    AgentGoogleauthComponent,
    AgentGraphComponent,
    AgentAttendanceComponent,
    AdminGoogleauthComponent,
    AgentTimegraphComponent,
    AgentChatgraphComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    ChartModule,
 
    NgxPaginationModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    MatButtonModule, MatToolbarModule, MatCardModule, MatInputModule, MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatInputModule,
    HttpModule,
    MatFormFieldModule, MatPaginatorModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
    // Overriding default routing to hash routing strategy
    //Ref: https://blog.fullstacktraining.com/404-after-refreshing-the-browser-for-angular-vue-js-app/
    // RouterModule.forRoot(AppRoutes, { useHash: true }),
    NgFlashMessagesModule.forRoot(),
    FlashMessagesModule.forRoot(),
    ColorPickerModule,
    Ng2SearchPipeModule,
    NgxDropzoneModule
  ],
  entryComponents: [
    CustomerComponent,
    ChatsComponent,
    MessageComponent,
    MessagesComponent,
    ChatboxmessageComponent,
    CustomerEmptyComponent,
  ],
  providers: [AuthGuard, AgentGuard, ApiService, ChatbotService,ChatService, CookieService,TimeblinkerService,ServicesService],
  bootstrap: [AppComponent]
})


export class AppModule { }



