import { Component, OnInit, Input, EventEmitter, ViewContainerRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { ApiService } from '../api.service';
import { NgFlashMessageService } from 'ng-flash-messages';
import { ActivatedRoute } from '@angular/router';
import { ChatService } from '../chat.service';
import { checkForAPIFailResponse } from '../../assets/js/utils';

@Component({
  selector: 'app-visitors',
  templateUrl: './visitors.component.html',
  styleUrls: ['./visitors.component.css']
})
export class VisitorsComponent implements OnInit {
  @ViewChild('viewContainerRef', { read: ViewContainerRef , static: true }) VCR: ViewContainerRef;
  @Input() someEvent = new EventEmitter<string>();
  isAgent: boolean = false;
  visitors: any;
  p: any;
  agents: any[] = []
  filteredData:any;
  username: any;
  pages: any;
  debug = false;
  componentsReferences = [];

  newarr = [];
  items = [{ name: "pakistan" }, { name: "africa" }, { name: "richard" }];
  constructor(private api: ApiService,
    private route: ActivatedRoute,
    private ngFlashMessageService: NgFlashMessageService,
    private CFR: ComponentFactoryResolver,
    private chatService: ChatService,
  ) { }

  ngOnInit() {
   //  this.chatService.joinRoom(localStorage.getItem("agentid"))
   this.isAgent = localStorage.getItem('user_role')=='agent'?true:false;
    this.getVisitor(0);
  }

  nameFilter() {
    let searchValue = (document.getElementById('_filter_name') as HTMLInputElement).value;
    if (searchValue) {
      this.filteredData = this.visitors.filter(e => {
        return e.user_name.toLowerCase().includes(searchValue)
      })
    } else {
      this.filteredData = this.visitors;
    }
  }

  countryFilter(){
    let searchValue = (document.getElementById('_filter_country') as HTMLInputElement).value;
    if(searchValue){
      this.filteredData = this.visitors.filter(e=>{
        return e.location.country.toLowerCase().includes(searchValue)
      })
    }
    else {
      this.filteredData = this.visitors;
    }
  }



  getVisitor(skip) {
    let id = localStorage.getItem('company_Id')?localStorage.getItem('company_Id'):localStorage.getItem('user');
    this.p = skip;
    this.api.getVisitors(skip,id).subscribe((res) => {
      let httpSuccess = checkForAPIFailResponse(res);
      if(httpSuccess){
        this.visitors = res.record;
        this.filteredData = JSON.parse(JSON.stringify(this.visitors));
        this.pages = res.data;
      }
    })
  }

  getValue(d) {
    if (d.name === "First-time visitor") {
      this.visitors = this.filteredData
      const filteredArr = this.visitors.reduce((acc, current) => {
        const x = acc.find(item => item.customer_email === current.customer_email);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      this.visitors = filteredArr
    }
    if (d.name === "Returning visitor") {
      this.visitors = this.filteredData
      this.visitors = this.visitors.filter(data => this.visitors.filter(email => email.customer_email === data.customer_email).length > 1)
    }
  }

  pageChanged(event) {
    this.p = event;
    this.getVisitor(event);
  }

}
