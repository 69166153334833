import { Component,OnInit,ViewContainerRef,ViewChild, Output,EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ChatService } from '../chat.service';
import {ServicesService} from '../services.service';
import $ = require('jquery');
import { ApiService } from '../api.service';
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {

  @ViewChild('viewContainerRef', { read: ViewContainerRef , static: true}) VCR: ViewContainerRef;
  public selfRef: MessageComponent;
  @Output() editmessage = new EventEmitter<string>();
  message:any;
  sender:any;
  time;
  updatemessage:any;
  newUpdateMessage:any;
  messageClass:any;
  customerName:string;
  fileType:string;
  fileLink:string;
  fileName:string;
  currentID;
  id:string;
  rerender;
  messagerating: string;

  constructor(
    private chatService: ChatService,
    private servicesData: ServicesService,
    private cdRef:ChangeDetectorRef,
    private api: ApiService
  ) {}

  ngOnInit() {
    if(this.message.sender == 'Agent'){
      this.messageClass = "Ymsg"
      this.customerName = "Me"
    }
    else{
      this.messageClass = "Mymsg_ag"
    }
  }

  ngAfterViewInit(){
    if(this.time){
      let date= new Date(this.time)
      let getHours=date.getHours();
      let getMinutes=date.getMinutes();
      let seconds=date.getSeconds();
        $("#time_"+getHours+getMinutes+seconds).text(this.updatemessage.time);
        $("#message_"+getHours+getMinutes+seconds).text(this.updatemessage.message);
    }
  }

  doRerender() {
    this.rerender = true;
    this.cdRef.detectChanges();
    this.rerender = false;
  }

  click(data){
   data.status="true"
   this.servicesData.changeMessage(data)
  }

  getPlainMessageHtml(messageText){
    return this.urlify(messageText)
  }

  urlify(text) {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    text = text.replace(urlRegex, function(url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    })
    return text
  }

  updateMessage(){
    if($('#editedMessage').val()){
      this.message.message = $('#editedMessage').val();
      this.message.doEdit = false;
      this.message.edited = true;
      let clientId = localStorage.getItem('selectedClientId');
      this.api.editMessage(this.message.message,clientId, this.message._id,this.message.time)
      this.chatService.updateMessage(this.message.message,clientId, this.message._id,this.message.time,'Agent')
    }
  }

  toogleOptionMenu(){
    if(!this.message.showOptionMenu)
      this.message.showOptionMenu = true
    else
      this.message.showOptionMenu = !this.message.showOptionMenu;
  }

  hideOptionMenu(){
    if(!this.message.showOptionMenu)
      this.message.showOptionMenu = false
    else
      this.message.showOptionMenu = false
  }

  editOptionClicked(){
    this.message.doEdit = true
    this.hideOptionMenu()
  }

  closeOptionClicked(){
    this.message.doEdit = false
    this.hideOptionMenu()
  }

}
