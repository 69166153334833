import { Component, OnInit } from '@angular/core';
import $ = require('jquery');
@Component({
  selector: 'app-chat-tutorial',
  templateUrl: './chat-tutorial.component.html',
  styleUrls: ['./chat-tutorial.component.css']
})
export class ChatTutorialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('[data-fancybox="gallery"]').fancybox({
      buttons: [
      "zoom",
      "share",
      "slideShow",
      "fullScreen",
      "download",
      "thumbs",
      "close"
      ],animationEffect: "zoom-in-out",
      transitionEffect: "rotate"
      });
  }
}
