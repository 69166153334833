import {EventEmitter, Injectable, Optional} from '@angular/core';
import 'rxjs/add/operator/map';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs/Observable';
const debug = false;

function writeImportantLog(message){
  if(debug){
    console.log('===============================');
    console.log(message);
    console.log('===============================');
  }
}

@Injectable()
export class ChatService {
    current_url : any;
    socket:any;
    message: any;
    socketConnected : boolean = true;
    pingInterval : any = null;
    pingWatcher : number = 0;
    customerEmail: string  = "";
    forceDisconnect : boolean = false;
    joinedRooms : string[] = []; // This will hold state of all joined rooms, so when socket re-connect we will rejoin those rooms automatically
    limitExceedMessageShown = false;
    onesignalInitiated : boolean = false;
    agentOneSignalPlayerId : string = '';
    static offlineModeEnabled : boolean = false;
    /* Event emitor for limit exceeding event */
    public onSocketLimitExceedTimerEnd: EventEmitter<{}> = new EventEmitter<{}>();
  /* Event emitor for limit exceeding event End */

  //send additional data like user type, user id,
  // and server will track and update the live status based on socket connection
   constructor( @Optional() private isCustomer?: boolean, @Optional() private project_Id?: string) {
    let isAgent,agentId,projectId;
    if(isCustomer){
      isAgent = false;
      agentId = null;
      isCustomer = true;
      projectId = project_Id;

      //TODO BELOW FUNCTION WAS JUST FOR TESTING OF CUSTOMER ONLINE PRESENCE
      // setTimeout(()=>{
      //   this.customerInitiate('irfanhayat38@yahoo.com');
      // },3000);
      //TODO REMOVE ABOVE CODE LATER :)
    }
    else {
      try{
        isAgent = localStorage.getItem('user_role')=='agent'?true:false;
        agentId = localStorage.getItem('agentid') || '';
        isCustomer = false;
      }
      catch (e){
        isAgent = false;
        isCustomer = true;
        if(debug) {
          console.log(e);
        }
      }
    }

    this.forceDisconnect = false;

    this.socket = io('/', {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax : 25000,
        reconnectionAttempts: Infinity,
        autoConnect: true,
        query:{isAgent,agentId,isCustomer,projectId}
    })

    /* Events of socket */
    this.socket.on('connect', () => {
      writeImportantLog('Socket is connected')
      document.querySelector(".limit-exceed-overlay").classList.add("status_hide");
      this.socketConnected = true;
      this.changeConnectedBadge();
      if(this.pingInterval){
        clearInterval(this.pingInterval);
      }
      this.pingInterval = setInterval(()=>{
          if(this.pingWatcher>3){
            writeImportantLog('Server not responding, re-establishing connection');

            if(!this.forceDisconnect){
              this.socket.connect();
            }
          } else {
            this.pingWatcher++;
          }
      },5000)

      if(this.joinedRooms.length>0){
        if(debug){
          console.log('=== joining rooms again after socket re-connect ===');
        }
        this.joinedRooms.forEach((e)=>{
          this.joinRoom(e);
        });
      }
      if(this.agentOneSignalPlayerId){
        this.socket.emit('set_onesignal_player_id',this.agentOneSignalPlayerId)
      } else if(localStorage.getItem('agentOneSignalPlayerId')){
        this.socket.emit('set_onesignal_player_id',localStorage.getItem('agentOneSignalPlayerId'))
      }
    });

    this.socket.on('disconnect', () => {
      writeImportantLog('Socket is disconnected, trying to connect again');
      if(!this.forceDisconnect){
        this.socket.connect();
        if(this.customerEmail){
          this.customerInitiate(this.customerEmail);
        }
      }
      this.socketConnected = false;
      this.changeConnectedBadge();
    });
    this.socket.on('notification', data => {
      this.socket.emit('notification');
      console.log("audio 1");
       //  let audio = new Audio();
      //     audio.src = "/assets/sounds/longexpected.mp3";
     //     audio.load();
     //     audio.play();
        });
    /* Events of socket */
    this.socket.on('ping', () => {
      writeImportantLog('Server ping received');
      this.socket.emit('ping-response',agentId)
      this.socketConnected = true;
      this.pingWatcher = 0
      this.changeConnectedBadge();
    });

    this.socket.on('customer-ping', () => {
      writeImportantLog('Server customer-ping received');
      this.socket.emit('ping-response')
      this.socketConnected = true;
      this.pingWatcher = 0
    });

    this.socket.on('limit-exceed', (message) => {
      writeImportantLog('Connection error'+message);
      if(!this.limitExceedMessageShown){
      //  this.limitExceedMessageShown = true;
        //this.forceDisconnect = true;
      //  this.socket.disconnect();
       // this.connectionlimitExceedUpdateBadge();
      //  setTimeout(()=>{
      //    this.limitExceedMessageShown = false;
     //     this.resumeTakingChats();
     //  },1000*60);
 
      }
      else {
        // TODO create a static div and only hide show if this error comes
      }
    });


    this.socket.on('reconnect_attempt', () => {
      writeImportantLog('Trying to reconnect...')
    });
    

   /* this.socket.on('active_connection_exist', () => {
      writeImportantLog('Connection refused due to active_connection_exist')
      this.disconnectSelf();
      this.changeConnectedBadge(true);
    }); */ 
    /* END */
  }

  public connectionlimitExceedUpdateBadge(){
    let timer = 60;
    document.querySelector(".limit-exceed-overlay").classList.remove("status_hide");
    let interval = setInterval(()=>{
      timer--;
      if(timer<=0){
        clearInterval(interval)
      }
      document.getElementById('active-status-text-in-overlay').innerHTML = `Connecting in ${timer}`;
      if(timer<=0){
        document.getElementById('active-status-text-in-overlay').innerHTML = `Trying to connect`;
        this.onSocketLimitExceedTimerEnd.emit({end:true});
      }
    },1000)

    if(document.getElementById("connected-status-img"))
      document.getElementById("connected-status-img").style.display = "none";
    if(document.getElementById("not-connected-status-img"))
      document.getElementById("not-connected-status-img").style.display = "none";
  }

  public stopTakingChats(){
     document.getElementById('active-status-text').innerHTML = "Going Offline mode";
     this.forceDisconnect = true;
     this.socket.disconnect();
     if(document.getElementById('chat-container')){
       document.getElementById('chat-container').style.display = 'none'
       document.getElementById('empty-illustration-container').style.display = 'block'
     }
     setTimeout(()=>{
       document.getElementById("connected-status-img").style.display = "none";
       document.getElementById("not-connected-status-img").style.display = "none";
       document.getElementById("airplan-mode-status-img").style.display = "block";
       document.getElementById('active-status-text').innerHTML = "Offline mode";
       ChatService.offlineModeEnabled = true;
     },2000)
  }

  public resumeTakingChats(){
    this.forceDisconnect = false;
    ChatService.offlineModeEnabled = false;
    this.socket.connect();
    document.getElementById("airplan-mode-status-img").style.display = "none";
    document.getElementById("not-connected-status-img").style.display = "block";
    document.getElementById('active-status-text').innerHTML = "Connecting";
  }

  public customerInitiate(email){
    if(debug){
      console.log('customerInitiate -> '+email)
    }
    this.socket.emit('customer-initiate',email)
    this.customerEmail = email;
  }

  public joinRoom(roomno){
    if(debug){
      console.log('Joining room# -> '+roomno)
    }
    this.socket.emit('join',roomno)
    if(this.joinedRooms.indexOf(roomno)== -1){
      this.joinedRooms.push(roomno);
    }
  }

  public leaveRoom(roomno){
    this.socket.emit('leave',roomno)
  }

  public sendMessage(message,id) {
    if(debug){
      console.log('char service user message -> '+message)
    }
    
    this.socket.emit('new-message', message,id);
  }

  public updateMessage(message,chatId,messageId,@Optional() time?: string,@Optional() sender?: string) {
    this.socket.emit('update-message', message,chatId,messageId,time,sender);
  }

  public getUpdateMessages = () =>{
    return Observable.create((observer) => {
      this.socket.on("update-message", (message,messageId,time,sender) => {
        let messages={
          messageId,
          message,
          time,
          sender
        }
        observer.next(messages);
      });
    });
  }

  public chatUpdate(data) {
    this.socket.emit('chat-update', data);
  }

  public getChatUpdate = () =>{
    return Observable.create((observer) => {
        this.socket.on('chat-update', (chat_id) => {
            observer.next(chat_id);
        });
    });
  }

  public disconnectSelf = () =>{
    this.forceDisconnect = true;
    if(this.pingInterval){
      clearInterval(this.pingInterval);
    }
    this.socket.disconnect();
  }

  public changeConnectedBadge(connectionRefused?: boolean){
    try{
      if(ChatService.offlineModeEnabled){
        return
      }
      if(connectionRefused != null && connectionRefused === true){
        document.getElementById("connected-status-img").style.display = "none";
        document.getElementById("not-connected-status-img").style.display = "block";
        document.getElementById('active-status-text').innerHTML = "Connection Refused";
      }
      else if(this.socketConnected){
        document.getElementById("connected-status-img").style.display = "block";
        document.getElementById("not-connected-status-img").style.display = "none";
        document.getElementById('active-status-text').innerHTML = "You're Connected";
      }
      else {
        document.getElementById("connected-status-img").style.display = "none";
        document.getElementById("not-connected-status-img").style.display = "block";
        document.getElementById('active-status-text').innerHTML = "Trying to connect...";
      }
    }
    catch (e){
      // No need to print exception
    }
  }

  requestNotificationPermissions() {
  if (Notification['permission'] !== 'denied') {
  Notification.requestPermission(function (permission) {
  });
  }
  }

  showDesktopNotification(sender,message,time) {
    this.requestNotificationPermissions();
    var instance = new Notification(
     sender,{
        body:message,
        icon:time,
     }
    );
    setTimeout(instance.close.bind(instance),3000);
    return false;
    }

  public getCustomer = () =>{
    return Observable.create((observer) => {
        this.socket.on('new-customer', (message) => {
            observer.next(message);
       
        });
    });
  }

  public getMessages = () =>{
      return Observable.create((observer) => {
          this.socket.on("new-message", (message) => {
            if(debug) {
              console.log('===========NEW MESSAGE RECEIVED ==========');
              console.log('=========== AT socket.on("new-message") ==========');
              console.log(message);
              console.log('=====================');
            }
            observer.next(message);
          });
      });
  }

  public removeCustomer(data){
    this.socket.emit('remove-customer',data);
  }

  public assignAgent(data){
    this.socket.emit('agent-assigned',data);
  }

  public checkOnlineAgents(data){
    this.socket.emit('online-agents',data);
  }

  public onlineAgents(){
    return Observable.create((observer) => {
        this.socket.on('online-agents', (data) => {
         observer.next(data);
        });
    });
  }




  public leftChat(chat_id){
    this.socket.emit('chat-left',chat_id);
  }

  public agentLeft(){
    return Observable.create((observer) => {
        this.socket.on('chat-left', (chat_id) => {
            observer.next(chat_id);
        });
    });

  }

  public logout(agentId){
    localStorage.clear();
    this.socket.emit('agent-logout',agentId);
  }

  public agentAssigned = () =>{
    return Observable.create((observer) => {
        this.socket.on('agent-assigned', (data) => {
            observer.next(data);
        });
    });
  }

  public getRemovingCustomer = () =>{
    return Observable.create((observer) => {
        this.socket.on('remove-customer', (data) => {
            observer.next(data);
        });
    });
  }

  public transferChat(data){
    this.socket.emit('transfer-chat',data);
  }

  public typing = (data)=>{
    this.socket.emit('typing',data);
  }

  public recievedTyping = () =>{
    return Observable.create((observer) => {
        this.socket.on('typing', (data) => {
            observer.next(data);
        });
    });
  }


  public test = ()=>{
    this.socket.emit('test',{'message':'emited'});
  }

  public async initOneSignal(){
    let playerId = await this.InitAndGetPlayerId()
    if(playerId){
      this.onesignalInitiated = true;
      this.agentOneSignalPlayerId = playerId.toString();
      this.socket.emit('set_onesignal_player_id',this.agentOneSignalPlayerId)
      localStorage.setItem('agentOneSignalPlayerId',this.agentOneSignalPlayerId);
    }
  }

  sendMessagess(data) {
    this.socket.emit('message', data);
  }
  sendMessageAgent( trigger, data){
    this.socket.emit('agentBotMessage',trigger,data);
  }
  receivedReply() {
    const observable = new Observable<any>(observer => {
      this.socket.on('reply', (data) => {
        observer.next(data);
      });
      // return () => {
      //   this.socket.disconnect();
      // };
    });
    return observable;
  }

   InitAndGetPlayerId(){
    const appId = '84407dd5-a0da-4fc5-b058-d2d85e853a1d';
    const OneSignal = window['OneSignal'] || [];

    return new Promise((resolve=>{
      OneSignal.push(async function() {
        OneSignal.init({
          appId: appId,
          notifyButton: {
            enable: true,
          },
          subdomainName: "vizzlive",
        })

        let playerId = await OneSignal.getUserId();
        if(playerId){
          resolve(playerId)
        }
      });
    }))
  }
}
