import { Component, OnInit, Input, EventEmitter, ViewContainerRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { ApiService } from '../api.service';
import { NgFlashMessageService } from 'ng-flash-messages';
import { ActivatedRoute } from '@angular/router';
import { checkForAPIFailResponse } from '../../assets/js/utils';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {
  @ViewChild('viewContainerRef', { read: ViewContainerRef , static: true}) VCR: ViewContainerRef;
  @Input() someEvent = new EventEmitter<string>();
  ticket: any;
  isAgent: boolean = false;
  ticketId: any;
  ticketDetails: any;
  filterlist: any;
  skip: any;
  p: any;
  pages: any;
  componentsReferences = [];
  today = new Date();
  yesterday = new Date(Date.now() - 86400000);
  dateModel = [
    { id: 1, name: "today", date: this.today },
    { id: 2, name: "yesterday", date: this.yesterday },
    { id: 3, name: "last week", date: this.today },

  ];
  filterdate:any;
  filter_ticketid:any;
  company_id:any;
  filteragent:String;
  filter_mediun:String;
  selected_filters:any;
  date_from:any;
  date_to:any;
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private ngFlashMessageService: NgFlashMessageService,
    private CFR: ComponentFactoryResolver,
  ) { }

  ngOnInit() {
    this.isAgent = localStorage.getItem('user_role')=='agent'?true:false;
    this.company_id=(localStorage.getItem('user_role')=='agent')?localStorage.getItem('company_Id'):localStorage.getItem('user');
    if(localStorage.getItem('tickets_filter_array')){
      this.selected_filters=JSON.parse(localStorage.getItem('tickets_filter_array'));
      this.filter_ticketid=this.selected_filters.filter_ticketid;
      this.filteragent=this.selected_filters.filteragent;
      this.filter_mediun=this.selected_filters.filter_mediun;
      this.date_from=this.selected_filters.date_from;
      this.date_to=this.selected_filters.date_to;
    }
    //this.selected_filters.agent_company=this.company_id;
    this.getPMSTicket(0,this.selected_filters,this.company_id);
  }


  getPMSTicket(skip,filters,company_id) {
    this.p = skip;
    this.api.getPmsTickets(skip,this.company_id,filters).subscribe((pmsData) => {
      let httpSuccess = checkForAPIFailResponse(pmsData);
      if(httpSuccess){
        this.ticket = pmsData.record;
        this.filterlist = this.ticket
        this.pages = pmsData.data;
      }
    })
  }

  getTicket(ticketId,ticket_medium) {
    if(ticket_medium=='pms'){
      let pms_url='https://updatemedaily.com/asm_ticktes/task_detail/'+ticketId;
      window.open(pms_url, "_blank");
    }

    // this.VCR.remove();
    // this.api.getTicketDetail(ticketId).subscribe((pmsData) => {
    //   this.ticketDetails = pmsData;
    //   this.createTicketDetails(pmsData);
    // })
  }

  pageChanged(event) {
    this.p = event;

    this.getPMSTicket(event,this.selected_filters,this.company_id);
  }
  getValue(d) {
    if (d === "today") {
      //this.ticket = this.filterlist
      //this.ticket = this.ticket.filter(data => new Date(data.created_on).getDate() === new Date(d.date).getDate())
      return new Date(d.date).getDate();
    }
    else if (d === "yesterday") {
      //this.ticket = this.filterlist
      //this.ticket = this.ticket.filter(data => new Date(data.created_on).getDate() === new Date(d.date).getDate())
      return new Date(d.date).getDate() -1;
    }
    else if (d === "last week") {
      //this.ticket = this.filterlist
      //this.ticket = this.ticket.filter(data => new Date(data.created_on).getDate() <= new Date(d.date).getDate() + 7)

    }
  }

  filters(){
    let filter_array={
      filter_ticketid:this.filter_ticketid,
      filteragent:this.filteragent,
      filter_mediun:this.filter_mediun,
      date_from:this.date_from,
      date_to:this.date_to
    };
    this.selected_filters=filter_array;
    // filter_array.push({filterdate:this.filterdate})
    localStorage.setItem("tickets_filter_array", JSON.stringify(filter_array));
    // if(this.filter_ticketid){
    // }else{
    // }
    // if(this.filteragent){
    // }else{
    // }
    // if(this.filter_mediun){
    // }else{
    // }
    // if(this.filter_ticketid&&this.filter_ticketid!=''){
    //   this.selected_filters['pms_ticketid']=this.filter_ticketid;
    // }else{
    //   if(this.selected_filters['pms_ticketid']){
    //     this.remove(this.selected_filters,'pms_ticketid')
    //   }
    // }
    //==================
    // if(this.filteragent&&this.filteragent!=''){
    //   this.selected_filters.employee=this.filteragent;
    // }else{
    //   if(this.selected_filters.employee){
    //     delete this.selected_filters.employee;
    //   }
    // }
    // if(this.filter_mediun&&this.filter_mediun!=''){
    //   this.selected_filters.ticket_medium=this.filter_mediun;
    // }else{
    //   if(this.selected_filters.ticket_medium){
    //     delete this.selected_filters.ticket_medium;
    //   }
    // }
    this.getPMSTicket(this.p,this.selected_filters,this.company_id)
  }
  remove(array, element) {
    const index = array.indexOf(element);
    array.splice(element, 1);
  }
}
