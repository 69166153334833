import {Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver, ViewEncapsulation} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { MessagesComponent } from '../messages/messages.component'
import { NgFlashMessageService } from 'ng-flash-messages';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicesService } from '../services.service';
import { checkForAPIFailResponse } from '../../assets/js/utils';
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  selector: 'app-archives',
  templateUrl: './archives.component.html',
  styleUrls: ['./archives.component.css'],
})

export class ArchivesComponent implements OnInit {
  isAgent: boolean = false;
  username: string;
  customerMessages: any;
  project:any;
  agent:any;
  componentsReferences = [];
  chatDate: any;
  pmsEmployees: any;
  pmsProjects: any;
  task_duedate: any;
  ticket_info: any;
  task_desc: any;
  task_status: any;
  project_milestone: any;
  task_assign_usr: any;
  customer: any;
  guest_customer_email:string;
  guest_customer_name:string;
  date;
  filterlist;
  ticketForm: FormGroup;
  submitted = false;
  id;
  message;
  subscription;
  today = new Date();
  submit;
  newdate = this.today;
  Id;
  pages: any;
  skip: any;
  p: any;
  listDivClass : string = 'col-md-12';
  yesterday = new Date(Date.now() - 86400000);
  dateModel = [
    { id: 1, name: "today", date: this.today },
    { id: 2, name: "yesterday", date: this.yesterday },
    { id: 3, name: "last week", date: this.today },

  ];
  customer_name:string;
  customer_email:string;
  project_name:string;
  filter_agentid:string;
  customer_status: string;
  filter_agent_name:string;
  date_from:string;
  date_to:string;
  rated = [
    { id: 1, name: "good" },
    { id: 2, name: "bad" },
    { id: 3, name: "not rated" },

  ]
  agent_email:String;
  agentid:String;
  agent_pms_company_id='5a436c65b5f713278d821b28';
  selected_filters:any;
  company_id:string;

  @ViewChild('viewContainerRef', { read: ViewContainerRef , static: true }) VCR: ViewContainerRef;
  @ViewChild('messages', { read: ViewContainerRef , static: true }) messagesVCR: ViewContainerRef;
  newId: any;
  rate: any;
  selectedCustomer: any;

  constructor(
    private api: ApiService,
    private CFR: ComponentFactoryResolver,
    private route: ActivatedRoute,
    private ngFlashMessageService: NgFlashMessageService,
    private router: Router,
    private formBuilder: FormBuilder,
    private servicesData: ServicesService,
  ) {
    this.agent_email=localStorage.getItem('agent_email');
    this.agentid = localStorage.getItem('agentid');
    this.route.queryParams.subscribe(params => {
      this.customer = {
        email: params.email ? params.email : '',
      }
    })
  }


  ngOnInit() {
    this.isAgent = localStorage.getItem('user_role')=='agent'?true:false;
    this.company_id=(localStorage.getItem('user_role')=='agent')?localStorage.getItem('company_Id'):localStorage.getItem('user');
    this.servicesData.newMessage.subscribe((result) =>{
      this.message=result

    });

    this.servicesData.id.subscribe(result=>{
      this.Id=result
    })

    this.username = localStorage.getItem('agent');
    this.api.get_agent_data().subscribe(result => {
      let httpSuccess = checkForAPIFailResponse(result);
      if(httpSuccess){
        result.agent.filter(res => {
          if (res.name === this.username) {
            this.username = res.name;
            console.log(res.name);
          }
        })
      }
    })

    this.createEmptyChatArea()
    if(localStorage.getItem('chats_filter_array')){
      this.selected_filters=JSON.parse(localStorage.getItem('chats_filter_array'));
      this.customer_name=this.selected_filters.customer_name;
      this.customer_email=this.selected_filters.customer_email;
      this.date_from=this.selected_filters.date_from;
      this.date_to=this.selected_filters.date_to;
    }
    if(this.company_id){
      if(this.selected_filters){
        this.selected_filters.company_Id=this.company_id;
      }else{
        this.selected_filters={company_Id:this.company_id, filter_agentid:this.agentid,};
      }
    }
    this.getMoreChats(0,this.selected_filters)

    this.api.getPmsEmpAndProjects(this.agent_email).subscribe((pmsData) => {
      let httpSuccess = checkForAPIFailResponse(pmsData);
      if(httpSuccess){
      // TODO API not giving employees record
      // this.pmsEmployees = pmsData.employees
      this.pmsProjects = pmsData.projects
      }
    })
    this.ticketForm = this.formBuilder.group({
      ticketName: ['', [Validators.required, , Validators.minLength(3)]],
      ticketdes:['',Validators.required],
      taskStatus: ['', Validators.required],
      employee: ['', Validators.required],
      projectName: ['', Validators.required],
      date1: ['', Validators.required],
      customer_email: ['', Validators.required],
      customer_name: ['', Validators.required]
    });
  }

  get f() { return this.ticketForm.controls; }

  getMoreChats(skip, filters) {
    this.p = skip;
    this.api.getCustomerData(skip,this.customer.email,filters).subscribe((messages) => {
      let httpSuccess = checkForAPIFailResponse(messages);
      if(httpSuccess){
        this.customerMessages = messages.record
        this.filterlist = messages.record
        this.pages = messages.data;
        this.project = messages.project;
        this.agent = messages.agent;
      }
    })
  }

  pageChanged(event) {
    this.p = event;
    this.getMoreChats(event,this.selected_filters);

  }

  onSubmit() {
    this.submitted = true;
    if (this.ticketForm.invalid) {
      return;
    }
  }

  rating(d) {
    let arr=[]
    if (d.name === "good") {
      this.customerMessages = this.filterlist
      let obj = this.customerMessages

      for (let index in obj) {
        let message = obj[index]['messages']
        for (let data in message) {
          if (message[data]['rating'] == "good") {
            arr.push(obj[index]);
            this.customerMessages = arr
          }
        }
      }
    }
    if (d.name === "bad") {

      this.customerMessages = this.filterlist
      let obj = this.customerMessages

      for (let index in obj) {
        let message = obj[index]['messages']
        for (let data in message) {
          if (message[data]['rating'] == "bad") {
            arr.push(obj[index]);
            this.customerMessages = arr
          }
        }
      }
    }
    if (d.name === "not rated") {
      this.customerMessages = this.filterlist
      let obj = this.customerMessages

      for (let index in obj) {
        let message = obj[index]['messages']
        for (let data in message) {
          if (message[data]['rating'] != "bad" && message[data]['rating'] != "good" ) {
            arr.push(obj[index]);
            this.customerMessages = arr
          }
        }
      }
    }
  }

  getValue(d) {
    if (d.name === "today") {
      this.customerMessages = this.filterlist
      this.customerMessages = this.customerMessages.filter(data => new Date(data.messages[0].time).getDate() === new Date(d.date).getDate())
    }
    if (d.name === "yesterday") {
      this.customerMessages = this.filterlist
      this.customerMessages = this.customerMessages.filter(data => new Date(data.messages[0].time).getDate() === new Date(d.date).getDate())
    }
    if (d.name === "last week") {
      this.customerMessages = this.filterlist
      this.customerMessages = this.customerMessages.filter(data => new Date(data.messages[0].time).getDate() <= new Date(d.date).getDate() + 7)
    }
  }

  getMessage(chat_id: any, date: any) {
    this.listDivClass = 'col-lg-6 col-md-6';
    document.getElementById('all_messages_of_customers').style.display = "block";

    this.api.getRating(chat_id).subscribe(res=> {
        let httpSuccess = checkForAPIFailResponse(res);
        if(httpSuccess){
          res.rating.filter(result=>{
            console.log("Result comming",result)
            this.selectedCustomer.ratings = result;
          })
        }
      })
    let customer = this.customerMessages.filter(x => x._id == chat_id)[0]
    this.VCR.remove();
    this.chatDate = date
    let componentFactory = this.CFR.resolveComponentFactory(MessagesComponent);
    let componentRef = this.VCR.createComponent(componentFactory);
    let currentComponent = componentRef.instance;
    currentComponent.ClientId = chat_id;
    currentComponent.history = true;
    currentComponent.customerName = customer.user_name
    currentComponent.getMessage({ chat_id: chat_id, assigned: true }); // Send the full data document in order to assign chat to specific agent
    currentComponent.selfRef = currentComponent;
    this.selectedCustomer = customer;
    this.guest_customer_name = this.selectedCustomer.user_name;
    this.guest_customer_email = this.selectedCustomer.customer_email;
  }

  createEmptyChatArea() {
    let componentFactory = this.CFR.resolveComponentFactory(MessagesComponent);
    let componentRef = this.VCR.createComponent(componentFactory);
    let currentComponent = componentRef.instance;
    currentComponent.disableTextArea = true;
    currentComponent.history = true;
    currentComponent.selfRef = currentComponent;
  }

  setDate(days: any) {
    if (days) {
      var date = new Date();
      if (days == 6) {
        this.task_duedate = date.setDate(date.getDate() + (days + (7 - date.getDay())) % 7);
      } else {
        this.task_duedate = date.setDate(date.getDate() + days - 1)
      }
    } else {
      this.task_duedate = ''
    }
  }

  submitTicket() {
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let guest_password = ''
    for (let i = 0; i < 10; i++)
      guest_password += possible.charAt(Math.floor(Math.random() * possible.length));

    let ticket_detail_obj = {
      "task_title": this.ticket_info,
      "task_description": this.task_desc,
      "task_status": this.task_status,
      "reportedBy": this.agent_email,
      "project_id": this.project_milestone,
      "milestone_id": this.project_milestone,
      "user_id": this.task_assign_usr,
      "duedate": this.task_duedate,
      "task_creationdate": Date.now(),
      "ticket_attachment": '',
      "guest_email": this.guest_customer_email,
      "guest_customer_name":this.guest_customer_name,
      "guest_password": guest_password,
      "agent_company_id":this.agent_pms_company_id
    }
    this.api.submitTicketPms(ticket_detail_obj).subscribe(result => {
      if (result.success) {
        let data = {
          ticketName:this.ticket_info,
          project_name: this.pmsProjects.filter(x => x._id == this.project_milestone)[0].project_name,
          pms_ticketid: result.last_inserted_id,
          guestEmail: this.guest_customer_email,
          //guestEmail: this.customer.email,
          guest_password: null,
          ticket_medium:'pms',
          description:this.task_desc,
          ticketPriority:this.task_status,
          employee:localStorage.getItem('agent'),
          date:Date.now(),
          agent_company:localStorage.getItem('company_Id')
        }
        this.api.submitTicket(data).subscribe(res => {
          if(res.success){
            this.ngFlashMessageService.showFlashMessage({     //Show the message on the front end
              messages: [res.message],
              dismissible: true,
              timeout: 50000,
              type: 'success'
            });

            this.ticket_info = "";
            this.task_desc = "";
            this.guest_customer_email = "";
            this.guest_customer_name = "";
          }
        })
        if (!result.updatedExisting) {
          data.guest_password = guest_password
        }

        this.api.sendTicketEmail(data).subscribe(null,(res) => {
          let httpSuccess = checkForAPIFailResponse(res);
          if(httpSuccess) {
            this.ticket_info = ''
            this.task_desc = ''
            this.task_status = ''
            this.project_milestone = ''
            this.task_assign_usr = ''
            this.task_duedate = ''
            let message_quote = '';

            if (result.new_user) {
              message_quote = "New account is created on PMS with your provided email and password is ";
            } else {
              message_quote = "ticket is created on your existing account of provided email and password is ";
            }
            message_quote = message_quote + result.guest_pass + " .Your ticket ID is : " + result.last_inserted_id + " Email is sent to user if it is valid";
            let messages = [message_quote]
            this.ngFlashMessageService.showFlashMessage({     //Show the message on the front end
              messages: messages,
              dismissible: true,
              timeout: 50000,
              type: 'success'
            });
          }
        })
      } else {
        let messages = ["Some error occured"]
        this.ngFlashMessageService.showFlashMessage({     //Show the message on the front end
          messages: messages,
          dismissible: true,
          timeout: 4000,
          type: 'danger'
        });
      }
    })
  }

  submitTicketData() {
    let data = {
      "ticketName": this.ticket_info,
      "ticketPriority": this.task_status,
      "employee": "ali",
      "description": this.task_desc,
      "milestone": "hard work",
      "guestEmail": "irfanhayat@gmail.com",
      "date": Date.now(),



    }
    this.api.submitTicket(data).subscribe(res => {
      checkForAPIFailResponse(res);
    })
  }

  save() {
    this.submitted = true;
    if (this.submitted == true && this.ticket_info == ' ' || this.task_status == ' ' && this.project_milestone == ' ' && this.task_assign_usr == ' ') {
      this.submit = "Please fill all required field";
      this.subscription.unsubscribe();
    }
  }

  onProjectSelect(project_id){
    let data={
      project_id:project_id,
      company_id:this.agent_pms_company_id
    }
    this.api.getPmsassignedemployeess(data).subscribe((pmsData) => {
      this.pmsEmployees = pmsData.employees;
    })
  }
  selectProject(){
    // if(this.project_name !== null && this.project_name !== ""){
      let prjectArray ={
        name : this.project_name, 
        company_Id:this.company_id
      }
      this.api.getSelectedProject(prjectArray).subscribe((messages) => {
        let httpSuccess = checkForAPIFailResponse(messages);
        if(httpSuccess){
          this.agent = messages;
        }
      })
    // }
  
  }
  filters(){
    let filter_array={
      customer_name:this.customer_name,
      customer_email:this.customer_email,
      company_Id:this.company_id,
      filter_agent_name:this.filter_agent_name,
      customer_status:this.customer_status,
      project_name: this.project_name,
      filter_agentid:this.agentid,
      date_from:this.date_from,
      date_to:this.date_to
    };
    this.selected_filters=filter_array;
    this.getMoreChats(this.p,this.selected_filters)

    localStorage.setItem("chats_filter_array", JSON.stringify(filter_array));
  }

  banAVisitor(){
    if(this.selectedCustomer && this.selectedCustomer._id){
      this.api.banAVisitor(this.selectedCustomer._id).subscribe(res=>{
        let httpSuccess = checkForAPIFailResponse(res);
        if(httpSuccess){
          if(res.hasOwnProperty('success') && res.success) {
            this.ngFlashMessageService.showFlashMessage({     //Show the message on the front end
              messages: [res.message],
              dismissible: true,
              timeout: 50000,
              type: 'success'
            });
            this.selectedCustomer.is_banned = true;
          }
        }
      })
    }
  }

  unbanAVisitor(){
    if(this.selectedCustomer && this.selectedCustomer._id){
      this.api.unbanAVisitor(this.selectedCustomer._id).subscribe(res=>{
        let httpSuccess = checkForAPIFailResponse(res);
        if(httpSuccess){
          if(res.hasOwnProperty('success') && res.success) {
            this.ngFlashMessageService.showFlashMessage({     //Show the message on the front end
              messages: [res.message],
              dismissible: true,
              timeout: 50000,
              type: 'success'
            });
            this.selectedCustomer.is_banned = false;
          }
        }
      })
    }
  }

  getFirstResponseTime(messagesArray){
    if(messagesArray && messagesArray.length>1){

      let agentMessage = messagesArray.filter(e=>e.sender=='Agent');
      if(agentMessage && agentMessage.length>0){
        let customerMessageTime = messagesArray[0].time;
        let agentMessageTime = agentMessage[0].time;

        // @ts-ignore
        const diffMilliSeconds = Math.abs(new Date(agentMessageTime) - new Date(customerMessageTime));
        const diffMinutes = Math.ceil(diffMilliSeconds / (1000 * 60 ));
        const diffDays = Math.ceil(diffMilliSeconds / (1000 * 60 * 60 * 24));
        return `<span class="first_response_time" style="${diffMinutes}>3?'color:red':'color:green'}">${diffMinutes} Min</span>`
      }
      else {
        return `<span style="color: red;">N/A</span>`
      }
    }
    else {
      return `<span style="color: red;">N/A</span>`
    }
  }

  removeChat(chatId) {
    Swal.fire({
      title: "PIN",
      text: "Write secret pin to delete this chat",
      input: 'password',
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
          if (result.isConfirmed) {
            this.api.removeChatById(chatId,result.value).subscribe((data) => {
              let httpSuccess = checkForAPIFailResponse(data);
              if (httpSuccess) {
                Swal.fire({
                  title: 'Succeed',
                  text: data.message,
                  icon: 'success'
                })
                let indexOfRemovedChat = this.filterlist.map(function (e) {
                  return e._id;
                }).indexOf(chatId);
                this.filterlist.splice(indexOfRemovedChat, 1);
              }
            })
          }
      }
    });
  }

  canRetake(){
    if(this.selectedCustomer){
      if(this.selectedCustomer.status=='taken')
        return false
      if(this.selectedCustomer.date ){
        let difference = new Date().getTime() - new Date(this.selectedCustomer.date).getTime();
        if(difference && difference>0 && (difference/(1000 * 60 *60))<4){
          return true
        }
        else return false
      }
      else return false
    }
  }

  retakeChat() {
    Swal.fire({
      title: "Confirm",
      text: "Are you sure to retake this chat.",
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        if (result.isConfirmed) {
          let data ={
           chat_id: this.selectedCustomer._id,
           agent_id: localStorage.getItem('agentid')
          }
          this.api.retakeChatById(data).subscribe((data) => {
            let httpSuccess = checkForAPIFailResponse(data);
            if (httpSuccess) {
              Swal.fire({
                title: 'Succeed',
                text: data.message,
                icon: 'success'
              })
            }
          })
        }
      }
    });
  }

}

