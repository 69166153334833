/* tslint:disable */
import {Component, Output, EventEmitter, OnInit, Input, ElementRef} from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ChatService } from '../chat.service';
import { ChatsComponent } from '../chats/chats.component';
import { ServicesService } from '../services.service';
import * as $ from 'jquery'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  profileImageUrl: string = '/assets/img/user_placeholder.png';
  @Input('username') username: any;
  isAgent: boolean = true;
  @Output() projectsAssigned = new EventEmitter<string>();
  value;

  constructor(
    private api: ApiService,
    private router: Router,
    private chatService: ChatService,
    private servicesData: ServicesService,
    // private string : String,
  ) {
    let agentName = localStorage.getItem("agent");
    if(agentName && agentName.length>0){
      this.username = agentName;
    }
    else {
      let userRole = localStorage.getItem("user_role");
      if(userRole && userRole.length>0){
        let companyName = localStorage.getItem("company_name");
        if(companyName && companyName.length>0){
          this.username = companyName;
        }
      }
    }
  }

  ngOnInit() {
    if (this.router.url == '/agent-dashboard' || this.router.url == '/assigned-projects' || this.router.url == '/history') {
      this.isAgent = true;
    }
    if (this.router.url == '/dashboard') {
      this.isAgent = false;
    }
    this.isAgent = localStorage.getItem('user_role')=='agent'?true:false;
    this.servicesData.sendAgent(this.username)

    let profile_image = localStorage.getItem("profile_image");
    if(profile_image){
      this.profileImageUrl = 'image/agents_profile/'+profile_image;
    }
  }

  onLogoutClick() {
    var agentId = localStorage.getItem('agentid');
    if (this.isAgent && agentId) {
      this.api.agentData(agentId).subscribe((data) => {
        var user = {
          _id: data._id,
          name: data.name,
          company_id: data.company_Id,
          status: 'left'
        }

        data.assigned_projects.forEach((projectId) => {
          this.api.getProjectDetail(projectId).subscribe((project) => {
            this.chatService.checkOnlineAgents({
              roomno: projectId,
              listeningroom: project.name,
              user: user,
    
            });
          });
        })
        this.chatService.logout(agentId);
        this.api.agentlogout();
        this.chatService.disconnectSelf();
        this.router.navigate(['/agent-login']);
        return false;
      })
    }
    else {
      this.api.logout();
      localStorage.clear();
      this.router.navigate(['/login']);
      return false;
    }
  }

  takeChats(value){
   this.servicesData.setTakeChatsValue(value)
  }

  goChats() {
    this.router.navigate(['/agent-dashboard'])
  }

  canDeactivate() {
    console.log("canDeactivate called...")
  }

}
