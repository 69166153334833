import { Component , HostListener, OnInit } from '@angular/core'; 
import * as io from 'socket.io-client';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit {
  private socket: any;
  public data: any;

  constructor(){
   // this.socket = io('https://127.0.0.1:3000');
  }

  ngOnInit(){
    // function for alert to show on window reload or leaving window

    //   window.addEventListener("beforeunload", function (e)
    //   {
    //     var confirmationMessage = "\o/";
//    this.socket.on('notification', data => {
 //     this.data = data;
  //    let audio = new Audio();
   ////   audio.src = "/assets/sounds/longexpected.mp3";
  //    audio.load();
   //   audio.play();
  //  });
    //     e.returnValue = confirmationMessage;     
    //     return confirmationMessage;              
    // });

  }

}
