import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { NgFlashMessageService } from 'ng-flash-messages';
import Swal from 'sweetalert2/dist/sweetalert2.js'


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  username: String;
  password: String;
  email: String;
  messages = [];
  data: any;

  constructor(
    private api: ApiService,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private ngFlashMessageService: NgFlashMessageService
  ) { }

  ngOnInit() {
    if (this.api.loggedIn()) {
      this.router.navigate(['/dashboard'])
    }
    else {
      this.setLoginPageDesign();
    }
  }

  onLogoutClick() {
    this.api.logout();
    this.router.navigate(['/login']);
    return false;
  }


  authenticate() {
    if(localStorage.getItem('token')||localStorage.getItem('agent-token')){
      let mic=(localStorage.getItem('token'))?'Admin Account':'Agent Account';
      this.messages[0] = 'You are aleady LoggedIn from '+mic;
      this.ngFlashMessageService.showFlashMessage({
        messages: this.messages,
        dismissible: true,
        timeout: 3000,
        type: 'danger'
      });

      this.router.navigate(['/login']);
    }else{
      const user = {
        username: this.username,
        email: this.email,
        password: this.password
      }
      this.api.authenticateUser(user).subscribe(data => {
        this.messages[0] = data.message
        if (data.success) {
          this.api.storeUserData(data.token, data.user.id,data.user.user_role,data.user);
          this.ngFlashMessageService.showFlashMessage({
            messages: this.messages,
            dismissible: true,
            timeout: 3000,
            type: 'success'
          });
         // this.router.navigate(['/dashboard']);
         this.router.navigate(['/admin-googleauth'])
         this.unsetLoginPageDesign();
        }
        else {
          Swal.fire({
            title: 'Login Failed',
            text: this.messages[0] || 'Something not fine',
            icon: 'error'
          })
          this.router.navigate(['/login']);
        }
      });
    }

  }


  setLoginPageDesign() {
    document.body.style.backgroundRepeat  = "no-repeat";
    document.body.style.backgroundImage = "linear-gradient(#b8dbdc, #05edf3)";
    document.body.style.height = "100%";
    document.getElementById('main-html-tag').style.height = "100%";
    localStorage.clear();
  }

  unsetLoginPageDesign() {
    document.body.style.backgroundRepeat  = "unset";
    document.body.style.backgroundImage = "unset";
    document.body.style.height = "unset";
    document.getElementById('main-html-tag').style.height = "unset";
  }


}
