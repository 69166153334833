import { Component, OnInit } from '@angular/core';
import * as CanvasJS from '../../assets/img/canvasjs.min';
import {ApiService} from '../api.service';
@Component({
  selector: 'app-agent-graph',
  templateUrl: './agent-graph.component.html',
  styleUrls: ['./agent-graph.component.css']
})
export class AgentGraphComponent implements OnInit {
  company_id:string;
  company_Id:string;
  agentid:string;
  selected_filters:any;
  isAgent:any;
  report:any
  isReport:boolean=false
  minDate:any
  maxDate:any
  visitorPercentage:any
  newReport: void;
  constructor(private api:ApiService){
 
    
    this.api.getagentGraph().subscribe(res=>{
      this.report=res
      this.isReport=false;
      this.visitorPercentage=this.report.totalVisitors;

      // this.agentReportBarChart();
      // this.agentsPresenceChart();
      // this.agentsPresenceStatsChart();
    this.agentsPresenceChatsChart();
     // this.agentAcceptMissedChat();
      this.agentRatingChat();
    })
  }

  ngOnInit() {
    this.isAgent = localStorage.getItem('user_role')=='agent'?true:false;
    this.company_id=(localStorage.getItem('user_role')=='agent')?localStorage.getItem('company_Id'):localStorage.getItem('user');
    if(this.company_id){
      if(this.selected_filters){
        this.selected_filters.company_Id=this.company_id;
      }else{
        this.selected_filters={company_Id:this.company_id, filter_agentid:this.agentid,};
      }
    }
   
  }
  agentAcceptMissedChat(){
    let chart = new CanvasJS.Chart("chartContainer",{
			theme: "light1", // "light2", "dark1", "dark2"
      title: {
        text: "Agent Accept & Missed Chats"
      },
    	data: [{
        type: "column",
        name: "Accept",
        legendText: "Accept",
        showInLegend: true, 
        dataPoints:[
          { label: "inam", y: 266.21 },
          { label: "wasiq", y: 302.25 },
          { label: "zain", y: 157.20 }
        ]
      },
      {
        type: "column",	
        name: "Missed",
        legendText: "Missed",
        axisYType: "secondary",
        showInLegend: true,
        dataPoints:[
          { label: "inam", y: 10.46 },
          { label: "wasiq", y: 2.27 },
          { label: "zain", y: 3.99 }
          
        ]
      }]
		});
    chart.render();
  }
  agentsPresenceChatsChart(){


    var chart = new CanvasJS.Chart("agent_chatsChart", {
      animationEnabled: true,
      indexLabel: "{y}",
      title:{
        text: "Accept & Offline Records of Chats"
      },
      axisX:{
        title: "Previous Record of Chats",
        minimum: -0.02,
        maximum: 3.02
      },
      axisY:{
        title:"Chats"
      },
      toolTip:{
        shared: true
      },
      data: [
      {
        type: "stackedArea100",
        name: "Accept",
        showInLegend: "true",
        dataPoints: [
          { y: this.report.todayChat , label: "Today" },
          { y: this.report.yesterdayChat, label: "Yesterday" },
          { y: this.report.lastWeekChat, label: "LastWeek" },
          { y: this.report.lastMonthChat, label: "LastMonth" }
        ]
      },
      {
        type: "stackedArea100",
        name: "Offline",
        showInLegend: "true",
        dataPoints: [
          { y: this.report.todayMissedChat || 0, label: "Today" },
          { y: this.report.yesterdayMissedChat|| 0, label: "Yesterday" },
          { y: this.report.lastWeekMissedChat|| 0, label: "LastWeek" },
          { y: this.report.lastMonthMissedChat|| 0, label: "LastMonth" }
        ]
      }]
    });
    chart.render();
  }
  agentRatingChat(){
    var chart = new CanvasJS.Chart("agnet_rating_chart", {
      animationEnabled: true,
      theme: "light2", // "light1", "light2", "dark1", "dark2"
      indexLabel: "{label}  {y}",
      title:{
        text: "Chats Rating"
      },
      axisY: {
        title: "Chats Rating"
      },
      data: [{        
        type: "column",  
        indexLabel: "{y}",
        showInLegend: true, 
        legendMarkerColor: "grey",
         legendText: "Total Chats Rating",
        dataPoints: [      
          { y: this.report.goodRating || 0, label: "GoodRating" },
          { y: this.report.unRated || 0,  label: "UnRating" },
          { y: this.report.badRating || 0,  label: "BadRating" },
        ]
      }]
    });
    chart.render();
  }
}
