import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { NgFlashMessageService } from 'ng-flash-messages';
import { checkForAPIFailResponse } from '../../assets/js/utils';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  messages = [];
  constructor(
    private api: ApiService,
    private router: Router,
    private ngFlashMessageService: NgFlashMessageService
  ) { }

  ngOnInit() {
    if (this.api.loggedIn()) {
      this.router.navigate(['dashboard'])
    }
  }

  registerUser(user) {
    let no_error=true;
    let error_message=[];
    if(user.username==''){
      no_error=false;
      error_message.push('Username');
    }
    if(user.email==''){
      no_error=false;
      error_message.push('Email');
    }else{
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!re.test(user.email)){
        error_message.push('Correct Email');
        no_error=false;
      }
    }
    if(user.company_name==''){
      no_error=false;
      error_message.push('Company Name');
    }
    if(user.company_url==''){
      no_error=false;
      error_message.push('Company Url');
    }
    if(user.password==''){
      no_error=false;
      error_message.push('Password');
    }
    if(no_error){
      this.api.registerUser(user).subscribe(data => {
        this.messages[0] = data.message
        if (data.success) {
          this.messages[0] += ' We are proceeding you to login'
          this.ngFlashMessageService.showFlashMessage({
            messages: this.messages,
            dismissible: true,
            timeout: 3000,
            type: 'success'
          });
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 3000);

        } else {
          this.ngFlashMessageService.showFlashMessage({
            messages: this.messages,
            dismissible: true,
            timeout: 3000,
            type: 'danger'
          });

          this.router.navigate(['register']);
        }
      });
    }else{
      this.messages[0] = ' Please insert '+error_message.join();
      this.ngFlashMessageService.showFlashMessage({
        messages: this.messages,
        dismissible: true,
        timeout: 3000,
        type: 'danger'
      });

      this.router.navigate(['register']);
    }
  }

  onLogoutClick() {
    this.api.logout();
    this.router.navigate(['/login']);
    return false;
  }
}
