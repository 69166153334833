import {Injectable, Optional} from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {HttpErrorResponse } from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { tokenNotExpired } from 'angular2-jwt';
import { Http, Headers, ResponseContentType  } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { allowPreviousPlayerStylesMerge } from '@angular/animations/esm2015/src/util';


@Injectable({
  providedIn: 'root'
})

export class ApiService {

  authToken: any;
  agentToken:any;
  userData: any;
  agent:any;

  constructor(
    private http: Http,
    private router: Router,
    private cookieService: CookieService,
  ) { }

  private handleError(error: HttpErrorResponse) {
    if(error && error.hasOwnProperty('status') && error.status == 401){
      Swal.fire({
        title: error.message || 'Login session expired',
        icon: 'error'
      }).then(() => {
        let userRole = localStorage.getItem("user_role");
        if(userRole && userRole=='company'){ window.location.href="/login"; }
        else { window.location.href="/agent-login"; }
        localStorage.clear();
      });
      return;
    }
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  };

  private appendHeaderToken (headers){
    this.loadToken();
    this.loadAgentToken();
    let token = null;
    if(this.authToken){
      token = this.authToken
    }
    else if(this.agentToken){
      token = this.agentToken;
    }
    headers.append('x-access-token',token);
    return headers;
  }

  private extractData(res: any) {
    let body = res;
    return body || {};
  }

  registerUser(user) {
    return this.http.post('/api/register', user).map(res => res.json())
  }

  rating(data){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    return this.http.post('/api/rating',data,{headers: headers}).map(res=>res.json()).catch(this.handleError);
  }

  storeUserData(token,user,user_role,userdetail) {
    localStorage.setItem('token', token);
    localStorage.setItem('user', user);
    localStorage.setItem('user_role', user_role);
    this.authToken = token;
    this.userData = user;
  }

  storeAgentData(token,user,id,user_role,data ) {
    localStorage.setItem('agent-token', token);
    localStorage.setItem('agent', user);
    localStorage.setItem('agentid', id);
    localStorage.setItem('user_role', user_role);
    localStorage.setItem('agent_email',data.user.agent_email);
    localStorage.setItem('timeId',data.user.agent_time);
    localStorage.setItem('company_Id',data.user.company_Id);
    localStorage.setItem('profile_image',data.user.profile_image||"image/agents_profile/default.png");    
    this.agentToken = token;
    this.agent = user;
  }
  authenticateUser(user) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post('/api/authenticate', user, {headers: headers})
      .map(res => res.json());
  }

  authenticateAgent(user) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post('/api/agentlogin', user, {headers: headers})
      .map(res => res.json());
  }
  getNewReport(minDate,maxDate){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '';
    if(minDate && maxDate){
      apiUrl = '/api/getNewReport';
      const url = `${apiUrl}/${minDate}/${maxDate}`;
      return this.http.get(url, {headers: headers})
        .map(res => res.json());
    }
    if(minDate && typeof maxDate === 'undefined'){
      apiUrl = '/api/getNewReport';
      const url = `${apiUrl}/${minDate}`;
      return this.http.get(url, {headers: headers}).map(res => res.json()).catch(this.handleError);
    }
    if(maxDate && typeof minDate === 'undefined'){
      apiUrl = '/api/getNewReport';
      const url = `${apiUrl}/${maxDate}`;
      return this.http.get(url, {headers: headers}).map(res => res.json()).catch(this.handleError);
    }
  }
  checkChatStatus(project_name) {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    headers.append('Content-Type', 'application/json');
    let apiUrl = '/api/checkChatStatus';
    const url = `${apiUrl}/${project_name}`;
    return this.http.get(url, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  transferChat(data){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = '/api/transferchat';
    return this.http.post(url,data ,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  getDashboard() {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    this.loadToken();
    this.comapanyId();
    headers.append('Authorization', this.authToken);
    headers.append('Content-Type', 'application/json');
    return this.http.get('/api/dashboard', {headers: headers}).map(res => res.json()).catch(this.handleError);
  }
 
  getGoogleauth(agentId) {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    this.loadToken();
    headers.append('Authorization', this.authToken);
    headers.append('Content-Type', 'application/json');
    let apiUrl = '/api/tfa/setup';
    const url = `${apiUrl}/${agentId}`;
    return this.http.get(url, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }
  getAdminGoogleAuth(adminId){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    headers.append('Authorization',this.authToken);
    headers.append('Content-Type', 'application/json');
    let apiUrl = '/api/admin/setup';
    const url = `${apiUrl}/${adminId}`;
    return this.http.get(url , {headers: headers}).map(res => res.json()).catch(this.handleError);

  }
  verifyAuth(token) {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = '/api/verify';
    return this.http.post(url, { token }, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }
  verifyAdminAuth(token){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = '/api/admin/verify';
    return this.http.post(url, {token},{headers:headers}).map(res => res.json()).catch(this.handleError);
  }

  getAgentboard() {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    this.loadAgentToken();
    this.AgentId();
    headers.append('Authorization', this.agentToken);
    headers.append('Content-Type', 'application/json');
    return this.http.get('/api/agent-board', {headers: headers}).map(res => res.json()).catch(this.handleError);
  }


  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  loadToken() {
    const token = localStorage.getItem('token');
    this.authToken = token;
  }

  loadAgentToken() {
    const token = localStorage.getItem('agent-token');
    this.agentToken = token;
  }

  comapanyId() {
    const user = localStorage.getItem('user');
    this.userData = user;
  }

  AgentId() {
    const user = localStorage.getItem('agent');
    this.agent= user;
  }

  agentlogout() {
    localStorage.removeItem('agent-token');
    localStorage.removeItem('agent');
  }

  AgentLogout(name): Observable<any> {
    let headers = new Headers();
    const options = { withCredentials: true, headers: headers };
    let apiUrl = '/api/checkstatus';
    const url = `${apiUrl}/${name}`;
    return this.http.post(url, {headers: options}).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
//   const options = { withCredentials: true, headers: requestHeaders };
// return this.httpClient.get(fullUri, options).pipe(
//   map(this.extractData),
//   catchError(catchError(this.errorMapperService.handleError))
// );

  loggedIn() {
    return tokenNotExpired('token');
  }


  agentlogIn(){
    return tokenNotExpired('agent-token');
  }

  agenttimelogout(data){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/agenttimelogout';
    return this.http.post(apiUrl, data, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  addproject(data, company_Id) {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/addproject';
    const url = `${apiUrl}/${company_Id}`;
    return this.http.post(url, data, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  editproject(data, project_Id) {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/editproject';
    const url = `${apiUrl}/${project_Id}`;
    return this.http.post(url, data, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  removeproject(data) {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = '/api/removeproject';
    return this.http.post(url, data, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  removeagent(data) {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = '/api/removeagent';
    return this.http.post(url, data, {headers: headers}).map(res => res.json()).catch(this.handleError);

  }

  assignAgent(data) {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = '/api/assignagent';
    return this.http.post(url, data, {headers: headers}).subscribe()
  }

  unassignAgent(data) {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = '/api/unassign-agent-from-project';
    return this.http.post(url, data, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  updateChatStatus(project_name){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/updateChatStatus';
    const url = `${apiUrl}/${project_name}`;
    return this.http.put(url, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }


  closeChatAgent(Id){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
   // let apiUrl = '/api/closeChat';
    //const url = `${apiUrl}/${chat_Id}`;
    const url ='/api/closeChat';
    return this.http.put(url,{Id}, {headers: headers}).subscribe(res => {})
  }
  closeChat(chat_Id){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/closeChat';
    const url = `${apiUrl}/${chat_Id}`;
    return this.http.put(url, {headers: headers}).subscribe(res => {})
  }


  get_customer_data(Id){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/get_customer_data';
    const url = `${apiUrl}/${Id}`;
    return this.http.get(url, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  get_agent_data(){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/agent/getAgent';
    const url = `${apiUrl}`;
    return this.http.get(url, {headers: headers}).map(res=>res.json()).catch(this.handleError);
  }
  get_all_messages(Id){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/get_messages';
    const url = `${apiUrl}/${Id}`;
    return this.http.get(url, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  // saveMessage(data,chat_Id){
  //   let headers = new Headers();
  //   headers = this.appendHeaderToken(headers);
  //   let apiUrl = '/api/saveMsg';
  //   const url = `${apiUrl}/${chat_Id}`;
  //   return this.http.put(url,data,{headers: headers}).subscribe(res => {
  //     res.json();
  //   })
  // }

  saveMessage(data,chat_Id){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/saveMsg';
    const url = `${apiUrl}/${chat_Id}`;
    return this.http.put(url,data,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  editMessage(message,chatId,messageId,@Optional() time?: string){
    let data = {
      message:message,
      time:time
    }
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/update_message';
    const url = `${apiUrl}/${chatId}/${messageId}`;
    return this.http.put(url,data,{headers: headers}).subscribe(res => {
      res.json();
    })
  }

  chat(data, project_Id,project_name,company_Id) {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/chat';
    let url = `${apiUrl}/${project_Id}/${project_name}/${company_Id}`;
    return this.http.post(url, data, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  addagent(data, company_Id) {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/addagent';
    let url = `${apiUrl}/${company_Id}`;
    return this.http.post(url, data, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  editagent(data, agent_Id) {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/editagent';
    let url = `${apiUrl}/${agent_Id}`;
    return this.http.post(url, data, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  assign(data, project_Id) {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/assignProj';
    let url = `${apiUrl}/${project_Id}`;
    return this.http.put(url, data, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  getProjects(company_Id): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/project';
    const url = `${apiUrl}/${company_Id}`;
    return this.http.get(url, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  getAgentData_ID(agentId){
    let headers = new Headers();
    let apiUrl = '/api/agents-detail';
    const url = `${apiUrl}/${agentId}`;
    return this.http.get(url, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  assignedProjects(data): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = '/api/assigned-projects';
    return this.http.post(url,data, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  getContacts(): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/find_customers';
    const url = `${apiUrl}`;
    return this.http.get(url, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  getVisitors(skip,companyId): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = `/api/getVisitors/${companyId}/${skip}`
    return this.http.get(url,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  getVisitorDetail(visitorId): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = `/api/getVisitor/${visitorId}`
    return this.http.get(url,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  get_message(chat_Id): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/get_message';
    const url = `${apiUrl}/${chat_Id}`;
    return this.http.get(url, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  sendTranscript(data){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = '/api/email_transcript';
    return this.http.post(url,data, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  downloadFile() {
    return this.http
      .post('/api/email_transcript', {
        responseType: ResponseContentType.Blob
      })
      .map(res => {
        return {
          filename: 'sent-mail.png',
          data: res.blob()
        };
      })
      .subscribe(res => {

        let url = window.URL.createObjectURL(res.data);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }, error => {

      }, () => {

      });
  }

  getTicket(): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    return this.http.get('/api/getTicket', {headers: headers}).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  Agent(company_Id): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/agent';
    const url = `${apiUrl}/${company_Id}`;
    return this.http.get(url, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  getAgent(project_id): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/agentName';
    const url = `${apiUrl}/${project_id}`;
    return this.http.get(url, {headers: headers}).map(res =>res.json()).catch(this.handleError);

  }

  getAllAgents(): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = '/api/agents';
    return this.http.get(url, {headers: headers}).map(res =>res.json()).catch(this.handleError);
  }

  findChat(): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/find';
    const url = `${apiUrl}`;
    return this.http.get(url, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  agentData(agentId): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/agentData';
    const url = `${apiUrl}/${agentId}`;
    return this.http.get(url, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  companyData(company_Id): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    this.loadToken();
    headers.append('Authorization', this.authToken);
    headers.append('Content-Type', 'application/json');
    let apiUrl = '/api/companyData';
    const url = `${apiUrl}/${company_Id}`;
    return this.http.get(url, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  getAgentName(name): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/agentName';
    const url = `${apiUrl}/${name}`;
    return this.http.get(url, {headers: headers}).map(res => res.json()).catch(this.handleError);

  }

  getProject(name): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/project';
    const url = `${apiUrl}/${name}`;
    return this.http.get(url, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }


  saveMsg(data) {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    this.http.post('/api/msg', data,{headers: headers}).subscribe(res => {
    })
  }

  getMsg(): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    return this.http.get('/api/getmsg', {headers: headers}).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  updateColorsAdmin(data) {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    return this.http.post('/api/update-colors', data,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  getProjectDetail(id){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = `/api/get-project/${id}`
    return this.http.get(url,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  getPmsTickets(skip,company_Id,filters): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = `/api/getTickets`;
    //let url = `/api/getTickets/${skip}/${company_Id}`;
    //let url = `https://updatemedaily.com/webServices/getTickets/${skip}`; // Get project Names and Employee names
    //return this.http.get(url,{headers: headers}).map(res => res.json());
    return this.http.post(url,{skip:skip,company_id:company_Id,filters:filters},{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  getTicketDetail(ticketId): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    //let apiUrl = 'http://localhost:8090/webServices/getTicket';
    let apiUrl = 'https://updatemedaily.com/webServices/getTicket';
    const url = `${apiUrl}/${ticketId}`;
    return this.http.get(url, {headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  getAssignedAgent(chatid){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = `/api/get-assigned-agent/${chatid}`
    return this.http.get(url,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  getPmsEmpAndProjects(usermail): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    //let url = 'http://localhost:8090/webServices/endpoints/get-ticket-details';
    let url = 'https://updatemedaily.com/webServices/endpoints/get-ticket-details'; // Get project Names and Employee names
    return this.http.post(url,{user_email:usermail},{headers: headers}).map(res => res.json()).catch(this.handleError);
  }
  getPmsassignedemployeess(data): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    //let url = 'http://localhost:8090/webServices/endpoints/get-assignedemployees';
    let url = 'https://updatemedaily.com/webServices/endpoints/get-assignedemployees'; // Get project Names and Employee names
    return this.http.post(url,{data},{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  submitTicketPms(data): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    headers.append('Access-Control-Allow-Headers', '*');
    headers.append('Access-Control-Allow-Methods', '*');
    headers.append('Access-Control-Allow-Origin', '*');
    //let url = 'http://localhost:8090/webServices/endpoints/submit-ticket';
    let url = 'https://updatemedaily.com/webServices/endpoints/submit-ticket'; // Get project Names and Emplyee names
    return this.http.post(url,data,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  submitTicket(data): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = '/api/tickets'; // Get project Names and Emplyee names
    return this.http.post(url,data,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  sendTicketEmail(data){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = '/api/email_ticket_details'; // Get project Names and Emplyee names
    return this.http.post(url,data,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  getCustomerData(skip,email,filters): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = '/api/get-customer-data';
    return this.http.post(url,{email:email,skip:skip,filters:filters},{headers: headers}).map(res => res.json()).catch(this.handleError);
  }
  getAgentChatGraph(filters): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = '/api/get-customer-chart';
    return this.http.post(url, {filters: filters},{headers:headers}).map(res =>res.json()).catch(this.handleError)
  }
  getSelectedProject(project): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = '/api/get-project';
    return this.http.post(url,{project},{headers:headers}).map(res => res.json()).catch(this.handleError);
  }
  getAgentTimeData(skip,filters): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = '/api/get-agent-time';
    return this.http.post(url,{skip:skip,filters:filters},{headers:headers}).map(res=>res.json()).catch(this.handleError);
  }

  removeChatById(id,pin): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = `/api/removeChat/${id}/${pin}`;
    return this.http.get(url,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  retakeChatById(data): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = `/api/retakeChat`;
    return this.http.post(url,data,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  getAgentData(): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let url = '/api/agents-detail';
    return this.http.get(url,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  getAgentByName(name): Observable<any> {
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/agents-details';
    const url = `${apiUrl}/${name}`;
    return this.http.get(url,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }


  updateAgentProfile(data){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    const url = '/api/agents-details';
    return this.http.post(url,data,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  sendChatAttachment(data){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    const url = '/api/upload-chat-attachment';
    return this.http.post(url,data,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  getReportRecord(){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/report';
    const url =apiUrl
    return this.http.get(url,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }
  getagentGraph(){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/graph';
    const url = apiUrl
    return this.http.get(url,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  getRating(id){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/rating';
    const url = `${apiUrl}/${id}`;
    return this.http.get(url,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  banAVisitor(id){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/ban-a-visitor';
    const url = `${apiUrl}/${id}`;
    return this.http.get(url,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

  unbanAVisitor(id){
    let headers = new Headers();
    headers = this.appendHeaderToken(headers);
    let apiUrl = '/api/unban-a-visitor';
    const url = `${apiUrl}/${id}`;
    return this.http.get(url,{headers: headers}).map(res => res.json()).catch(this.handleError);
  }

}


