import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';
import { ApiService } from '../api.service';
import { checkForAPIFailResponse } from '../../assets/js/utils';
import * as $ from 'jquery'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import {Router} from '@angular/router';

@Component({
  selector: 'app-agent-profile',
  templateUrl: './agent-profile.component.html',
  styleUrls: ['./agent-profile.component.css']
})
export class AgentProfileComponent implements OnInit {
  agentData;
  agentName;
  agentDescription;
  agentDetails;
  showModal: boolean = false;
  profileImageUrl: string = '/assets/img/user_placeholder.png';
  files: File[] = [];

  constructor(private data:ServicesService, private api: ApiService,
              private router: Router,) {
    this.data.getagent.subscribe((data)=>{this.agentData=data;})
  }

  ngOnInit() {
    let agentName =  localStorage.getItem("agent");
    if(agentName){
      this.api.getAgentByName(agentName).subscribe(res=>{
        let httpSuccess = checkForAPIFailResponse(res);
        if(httpSuccess){
          this.agentDetails=res
          this.agentName=res.name;
          this.agentDescription=res.description

          let profile_image = localStorage.getItem("profile_image");
          if(profile_image){
            this.profileImageUrl = 'image/agents_profile/'+profile_image;
          }
        }
      })
    }
  }

  /*Dropzone*/
  onSelect(event) {
    this.files.push(...event.addedFiles);
    if(this.files.length>0){
      for(let i=1;i<this.files.length;i++){
        this.files.splice(i,1);
      }
    }
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }
  /*End*/

  editAgent(updateName,description){
    let data = {
      name:updateName,
      description:description
    }

    let formData = new FormData();
    this.files.forEach(( e ) => {
      formData.append('profile_image',e, 'user profile image');
    });
    formData.append('data',JSON.stringify(data));


    this.api.updateAgentProfile(formData).subscribe(res=>{
      let httpSuccess = checkForAPIFailResponse(res);
      if(httpSuccess){
        if(res.success){
          Swal.fire({
            title: res.message || 'profile updated successfully',
            icon: 'success'
          }).then(() => {
            this.showModal = false;
            if(res.profileImage){
              localStorage.setItem('agent',updateName);
              localStorage.setItem('profile_image',res.profileImage);
            }
          })
        }
      }
    })
  }

  reload() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['./']);
  }

}
