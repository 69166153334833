import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiService } from '../api.service';
import { ChatService } from '../chat.service';
import { checkForAPIFailResponse } from '../../assets/js/utils';
import Swal from 'sweetalert2/dist/sweetalert2.js'

export interface myinterface {
  remove(index: number);
}

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})

export class CustomerComponent implements OnInit {
  @ViewChild('viewContainerRef1', { read: ViewContainerRef, static: true }) VCR: ViewContainerRef;
  public index: number;
  public selfRef: CustomerComponent;

  //interface for Parent-Child interaction
  public compInter: myinterface;

  showMap: boolean = true  // Used in toggle() function to Chage the background of box when a cross is clicked

  @Output() someEvent = new EventEmitter<any>();
  @Output() removeMessages = new EventEmitter<string>();
  @Input('name') name: String;
  @Input('status') status: String;
  @Input('id') id: String;
  @Input('record') record: any;
  read: boolean = false;
  constructor(
    private api: ApiService,
    private chatService: ChatService
  ) { }

  ngOnInit() {
    if(this.name && this.name.length>10){
      this.name = this.name.substr(0,10)
    }
    if (this.record.status != 'taken') {
      this.read = false;
    } else {
      this.read = true;
    }
  }

  callParent(id) {
    let agentId = localStorage.getItem('agentid');
    if(!agentId){
      Swal.fire({
        title: 'Your login session has been expired, please login again',
        icon: 'warning'
      }).then(() => {
        this.api.logout();
        localStorage.clear();
        window.location.href='agent-login'
        return false;
      })
      return;
    }
    this.api.agentData(agentId).subscribe(res => {
      let httpSuccess = checkForAPIFailResponse(res);
      if(httpSuccess){
        var data = {
          chat_id: id,
          assigned: this.read,
          agent_id: res._id,
          project_id: this.record.project_Id
        }  // Set the data and send to customers component
        this.someEvent.emit(data); // Emit data to parent componet that is customers
        this.read = true;          // Set the agent assigned to true temporarily
      }
    }, err => {
      console.log(err);
    });
  }


  close() {
    
    var Id ={
      chat_id : this.id, 
      agent_id: localStorage.getItem('agentid'),
      agent_name:localStorage.getItem('agent')

    }
    this.api.closeChatAgent(Id)
    this.compInter.remove(this.index)
    this.chatService.leftChat(this.id)
    this.chatService.leaveRoom(this.id)
   // localStorage.removeItem('selectedClientId');
    this.chatService.removeCustomer({ chat_id: this.id }) // To close the chat
    this.removeMessages.emit()

    document.getElementById('chat-container').style.display = 'none';
    document.getElementById('empty-illustration-container').style.display = 'block';
  }

  toggle() {
    if (this.showMap == true) {
      this.showMap = false
    } else {
      this.showMap = true
    }
  }
}
