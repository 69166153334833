import { Component, OnInit, ComponentRef, ElementRef, ComponentFactoryResolver, ViewChild, ViewContainerRef, Inject, Injectable, ɵConsole } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';
import { ChatboxmessageComponent } from '../chatboxmessage/chatboxmessage.component';
import { CookieService } from 'ngx-cookie-service';
import 'rxjs/add/operator/map';
import { ChatService } from '../chat.service';
import { NgFlashMessageService } from 'ng-flash-messages';
import * as EmailValidator from 'email-validator';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import 'rxjs/Rx';
import { trigger, state, style, animate, transition } from '@angular/animations'
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { FormBuilder,  Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { checkForAPIFailResponse } from '../../assets/js/utils';
// import { minizifrm } from '../../assets/cdn_vizz_livechat.js';
import { HostListener } from '@angular/core';
//declare const minizifrm: any;
// declare function minizifrm();
declare var $: any; 
interface Message {
  sender: string;
  message: string;
  time: Date;
  rating: string;
  comment:string;
  fileType: string;
  fileLink:string;
  fileName:string;
  saveId:any;
}


@Component({
  selector: 'app-proj-page',
  templateUrl: './proj-page.component.html',
  styleUrls: ['./proj-page.component.css'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),
      state('open', style({ opacity: 1 })),
      state('close', style({ opacity: 0 })),

      transition('close => open', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      transition('open => close', animate(600, style({ opacity: 0 }))),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class ProjPageComponent implements OnInit {
  @ViewChild('messagesContainer', { read: ViewContainerRef , static: false}) VCR: ViewContainerRef;
  // @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @ViewChild('downloadZipLink', {static: true}) private downloadZipLink: ElementRef;

  messagesReferences = [];
  messageText: string = null
  firstTimeUser: boolean;
  count: number = 0;
  typingCounter:number = 0;
  triggerName:number = 0;
  triggerEmail:number =0;
  text: any;
  fileType?: string;
  fileLink?: string; 
  fileName?: string;
  chats: any;
  data: any;
  project_Id: any;
  project_name: any;
  projectTitle: any;
  name: string;
  email: string;
  message: string;
  firstMessage:string;
  socket: any;
  trigger:any = 0;
  vizz_id: any;
  primaryColor: string;
  secondaryColor: string;
  agentStatus: string;
  topic: string;
  headMessage: string;
  displayOptions: boolean = false;
  displayEmail: boolean = false;
  displayLeave: boolean = false;
  displayRating: boolean = false;
  showcomment: boolean = true;
  outSide: boolean = false;
  transcriptEmail: string;
  fileUrl;
  typing: boolean
  chatTaken: boolean = true;
  animationState: string = 'open';
  assignedAgent: any;
  animate: boolean = false;
  rating: any;
  htmlStr;
  ChatForm: FormGroup;
  imgSrc="/assets/img/thumbs-up-light.png";
  clicked:number=0;
  comment;
  newComment:string;
  saveId:any;
  username;
  useremail;
  imgSrcDown="/assets/img/thumbs-down-light.png";
  boolean:boolean=false;
  boolean1:boolean=false;
  end:boolean=false;
  example = { userName: "", userChat: "" };
  company_id:any;
  chatService:any;
  componentsReferences = [];
  showFileUploadModal: boolean = false;
  leave_chat_text : string = '';
  files: File[] = [];
  title = 'Simple Chatbot';
  messageArray = [];
  botArray = [];
  synth:any;
  voices:any;
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private CFR: ComponentFactoryResolver,
    private cookieService: CookieService,
    private ngFlashMessageService: NgFlashMessageService,
    @Inject(DOCUMENT) private document: Document,
    private sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.synth = window.speechSynthesis;
    this.voices = this.synth.getVoices();
    this.project_Id = this.route.snapshot.paramMap.get('project_Id')
    this.chatService = new ChatService(true,this.project_Id.toString() );
    this.project_name = this.route.snapshot.paramMap.get('project_name');
    this.username=this.route.snapshot.paramMap.get('username')
    this.useremail=this.route.snapshot.paramMap.get('useremail')
    this.name=(this.route.snapshot.paramMap.get('username'))?this.route.snapshot.paramMap.get('username'):'';
    this.email=(this.route.snapshot.paramMap.get('useremail'))?this.route.snapshot.paramMap.get('useremail'):'';
    this.message=(this.route.snapshot.paramMap.get('userstep'))?this.route.snapshot.paramMap.get('userstep'):'';
  }

  ngOnInit() {
   this.username = (this.username === "undefined" ) ? "" : this.newComment;
    this.ChatForm = this.formBuilder.group({
      userName: [this.username, [Validators.required, , Validators.minLength(3)]],
      userChat : [this.useremail, Validators.required],
      userMessage: ['', Validators.required],
    });
      // this.botArray.push({name:'bot', message: "How can I help you"});
//       angular.element($window).bind('load', function() {
//     //put your code
// });
    //   let agent =   {
    //     "sender": "Agent",
    //     "agentName": "vizzBot",
    //     "message": "How can I help you",
    //     "time": new Date(),
    //     "fileType": "",
    //     "fileLink": "",
    //     "fileName": ""
    // }
    // setTimeout(() => {   
    //   this.pushMessage(agent)
    // //  this.speak("How can I help you");
    // }, 1000);
// @HostListener('window:load')
// onLoad() {
//   console.log('is window:load');
// }
      this.chatService.receivedReply().subscribe(data=> {
      // this.botArray.push({name:'bot', message: data.outputMessage});

      if (data.outputMessage.trigger =='' ) {
        this.end = data.outputMessage.end
      }else{
        this.trigger = data.outputMessage.trigger
      }
      if (this.end == true && this.agentStatus == 'online'){   
      this.sendMessages(this.firstMessage)
      }else{
        let agent =   {
          "sender": "Agent",
          "agentName": "vizzBot",
          "message": data.outputMessage.message,
          "time": new Date(),
          "fileType": "",
          "fileLink": "",
          "fileName": ""
      }
      this.pushMessage(agent)
      }
  
  //   let agent =   {
  //     "sender": "Agent",
  //     "agentName": "vizzBot",
  //     "message": data.outputMessage,
  //     "time": new Date(),
  //     "fileType": "",
  //     "fileLink": "",
  //     "fileName": ""
  // }
  // this.pushMessage(agent)

      //  bot message speck functionality
      // this.speak(data.outputMessage);
    });
    /* customer was joining room by proj name but agent join room by proj id, now doing changes so customer also join room by proj-name*/
    // this.chatService.joinRoom(this.project_name)
    this.chatService.joinRoom(this.project_Id)

    this.document.body.classList.add('loadchatbox');

    this.chatService.recievedTyping().subscribe((data) => {
      if (data.user == 'agent' && this.assignedAgent) {
   
        this.typing = true;
        if (!this.animate) {
          this.animate = true; // break condition for the fadeinout animation
          this.fadeInOut()
        }
        setTimeout(()=>{
          this.typing = false;
        },2000)
      
      }
    })

    this.api.getProjectDetail(this.project_Id).subscribe(data => {
      let httpSuccess = checkForAPIFailResponse(data);
      if(httpSuccess){
        this.primaryColor = data.primarycolor || '#00BCD4'
        this.secondaryColor = data.secondarycolor || '#56c2d0'
        this.company_id=data.company_Id;
      }
    })

    this.chatService.checkOnlineAgents({ roomno: this.project_Id, listeningroom: this.project_name, timeId:localStorage.getItem('timeId') })

    this.chatService.onlineAgents().subscribe((data) => {
      if(data.project_id == this.project_Id){
       if(data.project_id == this.project_Id && typeof data.data != 'undefined'){
        this.agentStatus = 'online'
        this.topic = "We're online"
      //  this.leave_chat_text = 'Leave Chat';
        this.headMessage = "Enter the details and hop in."
      } else {
        this.agentStatus = "offline"
        this.topic = "Support is offline right now"
       // this.leave_chat_text = '';
        this.headMessage = 'Leave your topic we will get back to you soon...'
        this.typing = false;
      }
    }
    })

    this.chatService.agentLeft().subscribe((chat_id) => {
      if (chat_id == this.vizz_id) {
        this.agentStatus = 'online'
        this.topic = "Agent left the chat"
        this.typing = false
      }
    })

    // TODO this vizz_id is not storing in cookies with real domain but saving in localstorage
    // Maybe due to secure is false or http only is false or what ever check this on monday

    // TODO comment below two lines for testing
    // this.vizz_id = this.cookieService.get('vizz_id').replace('j:', '')
    // this.vizz_id = this.vizz_id.replace(/"/g, "");

    // TODO: Now we replace getting vizz_id from cookies to localstorage
    this.vizz_id = localStorage.getItem('vizz_id');
    
    
    var msg_time     = localStorage.getItem('last_msg_time');
    if (msg_time != null){
     let date1 = new Date();
     let date2 = new Date(msg_time)
     let dateDifference =  date1.getTime() - date2.getTime();
     let Difference_In_Days = dateDifference / (1000 * 3600 * 24);
     if (Difference_In_Days > 1){
       window.localStorage.removeItem('vizz_id')
       window.localStorage.removeItem('last_msg_time')
       this.firstTimeUser = true;
     }
    }
    this.projectTitle = this.project_name.split('_').join(' ')

    this.chatService.getMessages().subscribe((message) => {
      this.typing = false;
      this.chatTaken = true;
      this.animate = false;
      if (!this.assignedAgent) {
        this.api.getAssignedAgent(this.vizz_id).subscribe((assignedagent) => {
          let httpSuccess = checkForAPIFailResponse(assignedagent);
          if(httpSuccess){
            this.assignedAgent = assignedagent
            this.pushMessage(message)
           // localStorage.setItem('last_msg_time',message.time);
          }
        })
      } else {
        
        if(message.saveId == '0'){
          this.saveId =message.saveId
         
        }
        if(message.saveId == '1'){
          this.saveId =message.saveId
        }
        this.pushMessage(message)
        let last_msg_time =  localStorage.getItem('last_msg_time')
        if (message.sender == 'Agent') {
          if(last_msg_time){
           var date1 = new Date();
           var date2 = new Date(last_msg_time)
           var dateDifference =  date1.getTime() - date2.getTime();
           if (dateDifference > 15000){
            this.playAudio()
           }
           localStorage.setItem('last_msg_time',message.time);
          }
          if (last_msg_time == null){
            localStorage.setItem('last_msg_time',message.time);
            this.playAudio()
          }
       //   this.chatService.showDesktopNotification(message.sender,message.message,message.time); //Recieve Push Notification
        }
      }

    });

    this.chatService.getUpdateMessages().subscribe((message) => {
      this.updatePushMessage(message,message.time)
    });

    if (this.vizz_id) {
      this.leave_chat_text = 'Leave Chat';
      this.chatService.joinRoom(this.vizz_id)
      this.api.get_message(this.vizz_id).subscribe(res => {
        let httpSuccess = checkForAPIFailResponse(res);
        if(httpSuccess){
          if (res == null) {
            this.cookieService.delete('vizz_id', '/')
            this.firstTimeUser = true;
          }
          else {
            if (res.status == "offline" || res.status == "missed"  ){
              window.localStorage.removeItem('vizz_id')
              window.localStorage.removeItem('last_msg_time')
               this.firstTimeUser = true;
             }else{
            // this.topic = res.messages[0].message
            this.name = res.user_name
       
            this.api.getAssignedAgent(this.vizz_id).subscribe((assignedagent) => {
              let httpSuccess = checkForAPIFailResponse(assignedagent);
              if(httpSuccess){
                this.assignedAgent = assignedagent
                res.messages.forEach(message => {
                  // Set the listener to listen for the evet and on message enter create new <chat-box-message> on frontends
                  this.pushMessage(message)
                });
              
              }
            })
          }
        }
        }
      })
    } else {
      this.firstTimeUser = true;
    }


    this.chatService.getRemovingCustomer().subscribe((data) => {
      if (data.chat_id == this.vizz_id) {
        this.firstTimeUser = false;
        if (this.VCR !== undefined) {
          this.VCR.remove()
        }
      
      }
    })


    this.chatService.onSocketLimitExceedTimerEnd.subscribe({
      next: () => {
        setTimeout(()=>{
          this.chatService.checkOnlineAgents({ roomno: this.project_Id, listeningroom: this.project_name ,timeId:localStorage.getItem('timeId')})
        },3000)
      }
    })
  }

  sendMessagess(){
        if (this.end == false && this.agentStatus == 'offline'){
        const data = { message: this.message };
        // this.chatService.sendMessagess(data);
        this.chatService.sendMessagess(this.trigger);
          if(this.trigger == 0){
            this.firstMessage = this.message
          }else if(this.trigger == 1){
          this.name = this.message;
        }else if(this.trigger == 2){
          this.email = this.message
        }
        // this.messageArray.push({name:'you', message:this.message});
              // if(this.agentStatus=='offline'){
                let agent =   {
                  "sender": "customer",
                  "agentName": "offline",
                  "message": this.message,
                  "time": new Date(),
                  "fileType": "",
                  "fileLink": "",
                  "fileName": ""
                  }
                this.message = '';
                this.pushMessage(agent)
              // }
    
          // if(this.agentStatus=='online'){
          //   this.sendMessages(this.message,this.fileType,this.fileLink,this.fileName)
          // }
      }else{
        this.sendMessages(this.message,this.fileType,this.fileLink,this.fileName)
      }
  }

 speak(string) {
  let u = new SpeechSynthesisUtterance(string);
  u.text = string;
  u.lang = "en-US";
  u.volume = 1; //0-1 interval
  u.rate = 1;
  u.pitch = 1; //0-2 interval
  this.synth.speak(u);
}

  
  thumbs(value) {
    this.rating = value
    let data={
      rating:value,
      id:this.vizz_id
    }
    this.imgSrc="/assets/img/thumbs-up.png ";
    this.imgSrcDown="/assets/img/thumbs-down.png";
    if(this.rating==="good"){
      this.boolean1=true;
      this.htmlStr = '<p color="blue">Please add comment</p>'
    }
    else if(this.rating==="bad"){
      let data={
        rating:this.rating,
        id:this.vizz_id
      }
      this.boolean=true;
      if(!this.newComment){
        this.htmlStr = '<p color="blue">Please add comment</p>'
      }
    }
  }

  sendComment(value){
    this.newComment=value;
    if(this.vizz_id){
      //this.displayRating = false;
      // this.boolean1=false;
      // this.boolean=false;
      let data={
        rating:this.rating,
        id:this.vizz_id,
        comment:value
      }
      this.api.rating(data).subscribe(res=>{
        checkForAPIFailResponse(res);
      })
      if(this.rating && this.rating=='bad'){
        this.boolean1=false;
      }else{
        this.boolean=false;
      }
      this.displayOptions = true;
        // this.displayLeave = false
        this.outSide = true;
        this.name = '';
        this.email = '';
        this.end = false;
        this.trigger = 0;
        var i = this.count  
        if (this.count > 0){
          for(i; i >= 0; i--){
            if (this.count > 0){
              this.count--
            }
          }
        }
        // this.minizifrm();
        this.imgSrc="/assets/img/thumbs-up-light.png";
        this.imgSrcDown="/assets/img/thumbs-down-light.png";
       if (this.agentStatus =='online'){
        this.topic = "We're online"
       }
       if (this.agentStatus =='offline'){
        this.topic = "Support is offline right now"
       }
      this.leave_chat_text = '';
      this.firstTimeUser = true;
      // this.htmlStr='Thanks for your valued response';
      // this.showcomment=false;
    

      // setTimeout(()=>{ window.location.reload(); });
    }
    else if(!this.vizz_id){
       this.htmlStr='Kindly start with us first';
    }
    // else if(!this.rating){
    //   // this.htmlStr='Please rate this chat';
    // }
    this.comment=" ";

  }
  // sendMessage(text: any, fileType?: string, fileLink?: string, fileName?: string) {
  //   if (text) {
  //     if (!this.firstTimeUser) {
  //       var newtext = text.replace("\n", '');
  //       var actualtext = newtext.replace(/\s/g,'')
  //       if (text != '' && actualtext.length > 0 &&typeof text != 'undefined'){ 
  //         var message: Message = {
  //           sender: "customer",
  //           message: text,
  //           time: new Date(),
  //           rating: this.rating,
  //           comment:this.newComment,
  //           fileType: fileType || '',
  //           fileLink: fileLink || '',
  //           fileName: fileName || ''
  //         }
  //       // }
       

  //       this.messageText = ''

  //       var data = {
  //         project_id: this.project_Id, // It will emit the event to all agents joined the project in future can change it to only assigned agent so only he can listen
  //         chat_id: this.vizz_id,
  
  //       }
  //       this.api.saveMessage(message, this.vizz_id).subscribe(res => {
  //         // let last_msg_time =  localStorage.getItem('last_msg_time')
  //         // if (this.agentStatus =="offline" && last_msg_time == null){
  //         //     let agent =   {
  //         //           "sender": "Agent",
  //         //           "agentName": "offline",
  //         //           "message": "Agent is Offline Right Now. Please describe the issue . We Contact with you as soon as possible ",
  //         //           "time": "",
  //         //           "fileType": "",
  //         //           "fileLink": "",
  //         //           "fileName": ""
  //         //       }
  //         //       this.pushMessage(agent)
  //         //      // let last_msg_time =  window.localStorage.setItem('last_msg_time', message.time)
  //         //      localStorage.setItem('last_msg_time',"message")
  //         //  }   
  //         if(res.hasOwnProperty('success') || res.hasOwnProperty('sender')){
  //           if(!res.success && !res.sender){
  //             Swal.fire({
  //               title: res.message || 'Something not fine...',
  //               icon: 'error'
  //             })
  //           }
  //           else {
  //             this.chatService.chatUpdate(data)
  //             this.chatService.sendMessage(message, this.vizz_id)
  //           }
  //         }
  //         else {
  //           Swal.fire({
  //             title: 'Something not fine there...',
  //             icon: 'error'
  //           })
  //         }

  //       });
  //     }
  //     }
  //     else {
  //       if (EmailValidator.validate(this.email)) {
  //         this.data = {
  //           name: this.name,
  //           email: this.email,
  //           message: this.message,
  //           agents: this.agentStatus,
  //           rating: this.rating,
  //           fileType: fileType || '',
  //           fileLink: fileLink || '',
  //           fileName: fileName || ''
  //         }
  //         this.transcriptEmail = this.email
  //         this.api.chat(this.data, this.project_Id, this.project_name,this.company_id).subscribe(res => {
  //           if(res.hasOwnProperty('success') || res.hasOwnProperty('customer_email')){
  //             if(!res.success && !res.customer_email){
  //               Swal.fire({
  //                 title: res.message || 'Something not fine...',
  //                 icon: 'error'
  //               })
  //             }
  //             else{
  //               if (this.agentStatus == "online" ) {
  //                 this.chatTaken = false;
  //                 this.animate = true;
  //                 this.topic = this.message
  //                 this.firstTimeUser = false
  //                 this.vizz_id = res._id
              
  //                 this.chatService.joinRoom(res._id)
  //                 setTimeout(() => {
  //                   res.messages.forEach(message => {
  //                     this.pushMessage(message)
  //                   });
  //                 }, 1000);

  //                 //setting vizz_id into localstorage because cookie of vizz_id is not storing
  //                 this.leave_chat_text = 'Leave Chat';

  //                 if(localStorage.getItem('vizz_id') === null ){
  //                   localStorage.setItem('vizz_id',res._id);
  //                 }
  //                 this.fadeInOut()
  //                 this.chatService.customerInitiate(this.email);
                  
  //               }
  //               else {
  //                   this.headMessage = 'Thanks we received your message will get back to you soon..'
  //                   this.chatTaken = false;
  //                   this.animate = true;
  //                   this.topic = this.message
  //                   this.firstTimeUser = false
  //                   this.vizz_id = res._id
  //                   this.chatService.joinRoom(res._id)
  //                   setTimeout(() => {
  //                     res.messages.forEach(message => {
  //                       this.pushMessage(message)
  //                     });
  //                   }, 50);
  //                    let last_msg_time =  localStorage.getItem('last_msg_time')
  //                     if (this.agentStatus =="offline" && last_msg_time == null){
  //                         let agent =   {
  //                               "sender": "Agent",
  //                               "agentName": "offline",
  //                               "message": "Thank you for reaching out to us our agent is offline right now. We will get in touch with you very soon.",
  //                               "time": new Date(),
  //                               "fileType": "",
  //                               "fileLink": "",
  //                               "fileName": ""
  //                           }
  //                           setTimeout(() => {   
  //                               this.pushMessage(agent)
  //                           }, 50);
                    
  //                         // let last_msg_time =  window.localStorage.setItem('last_msg_time', message.time)
  //                         localStorage.setItem('last_msg_time',"message")
  //                     }  
  //                   //setting vizz_id into localstorage because cookie of vizz_id is not storing
  //                  // this.leave_chat_text = 'Leave Chat';
  //                 this.leave_chat_text = 'Leave Chat';
  //                   if(localStorage.getItem('vizz_id') === null ){
  //                     localStorage.setItem('vizz_id',res._id);
  //                   }
  //                   this.fadeInOut()
  //                   this.chatService.customerInitiate(this.email);
                    
  //               }
  //             }
  //           }
  //           else {
  //             Swal.fire({
  //               title: 'Something not fine there...',
  //               icon: 'error'
  //             })
  //             console.log(res);
  //           }
  //         }, err => {
  //           console.log(err);
  //         });
  //       }
  //       else {
  //         this.headMessage = 'Enter valid email address'
  //       }
  //     }
  //   }  
  // }
  sendMessage(text: any, fileType?: string, fileLink?: string, fileName?: string) {
    if (text) {
      if (!this.firstTimeUser) {
        var newtext = text.replace("\n", '');
        var actualtext = newtext.replace(/\s/g,'')
        if (text != '' && actualtext.length > 0 &&typeof text != 'undefined'){
      
          var message: Message = {
            sender: "customer",
            message: text,
            time: new Date(),
            rating: this.rating,
            comment:this.newComment,
            fileType: fileType || '',
            fileLink: fileLink || '',
            fileName: fileName || '',
            saveId: this.saveId,
          }
        // }
        this.messageText = ''
        
        var data = {
          project_id: this.project_Id, // It will emit the event to all agents joined the project in future can change it to only assigned agent so only he can listen
          chat_id: this.vizz_id,        
      }
        this.triggerName == 0
        this.triggerEmail == 0
        this.api.saveMessage(message, this.vizz_id).subscribe(res => {
          // let last_msg_time =  localStorage.getItem('last_msg_time')
          // if (this.agentStatus =="offline" && last_msg_time == null){
          //     let agent =   {
          //           "sender": "Agent",
          //           "agentName": "offline",
          //           "message": "Agent is Offline Right Now. Please describe the issue . We Contact with you as soon as possible ",
          //           "time": "",
          //           "fileType": "",
          //           "fileLink": "",
          //           "fileName": ""
          //       }
          //       this.pushMessage(agent)
          //      // let last_msg_time =  window.localStorage.setItem('last_msg_time', message.time)
          //      localStorage.setItem('last_msg_time',"message")
          //  }   
          if(res.hasOwnProperty('success') || res.hasOwnProperty('sender')){
            if(!res.success && !res.sender){
              Swal.fire({
                title: res.message || 'Something not fine...',
                icon: 'error'
              })
            }
            else {
              this.chatService.chatUpdate(data)
              this.chatService.sendMessage(message, this.vizz_id)
            }
          }
          else {
            Swal.fire({
              title: 'Something not fine there...',
              icon: 'error'
            })
          }

        });
      }
      }
      else {
        // if (EmailValidator.validate(this.email)) {
          this.data = {
            name: this.name,
            email: this.email,
            message: this.message,
            agents: this.agentStatus,
            rating: this.rating,
            fileType: fileType || '',
            fileLink: fileLink || '',
            fileName: fileName || ''
          }
          this.transcriptEmail = this.email
          this.api.chat(this.data, this.project_Id, this.project_name,this.company_id).subscribe(res => {
            var res = res.data;
            //if(res.hasOwnProperty('success')){
               if(res.hasOwnProperty('success') || res.hasOwnProperty('customer_email')){
            //    if(!res.success ){
            // //    if(!res.success && !res.customer_email){
            //     Swal.fire({
            //       title: res.message || 'Something not fine...',
            //       icon: 'error'
            //     })
            //   }
            //   else{
                if (this.agentStatus == "online" ) {
                  this.chatTaken = false;
                  this.animate = true;
                  // this.topic = this.message
                  this.firstTimeUser = false
                  this.vizz_id = res._id
              
                  this.chatService.joinRoom(res._id)
                  console.log("I----------------",message)
                  setTimeout(() => {
                    res.messages.forEach(message => {
                      this.pushMessage(message)
                    });
                  }, 50);

                  //setting vizz_id into localstorage because cookie of vizz_id is not storing
                  this.leave_chat_text = 'Leave Chat';

                  if(localStorage.getItem('vizz_id') === null ){
                    localStorage.setItem('vizz_id',res._id);
                  }
                  this.fadeInOut()
                  this.chatService.customerInitiate(this.email);
                  
                }
                else {
                    this.headMessage = 'Thanks we received your message will get back to you soon..'
                    this.chatTaken = false;
                    this.animate = true;
                    // this.topic = this.message
                    this.firstTimeUser = false
                    this.vizz_id = res._id
                    this.chatService.joinRoom(res._id)
                    setTimeout(() => {
                      res.messages.forEach(message => {
                        this.pushMessage(message)
                      });
                    }, 50);
                    let last_msg_time =  localStorage.getItem('last_msg_time')
                    if (this.agentStatus =="offline" && last_msg_time == null){
                        let agent =   {
                              "sender": "Agent",
                              "agentName": "offline",
                              "message": "Our agent is not online.can you share your query and contact and we can come  back to you",
                              "time": new Date(),
                              "fileType": "",
                              "fileLink": "",
                              "fileName": ""
                          }
                          setTimeout(() => {   
                              this.pushMessage(agent)
                          }, 50);
                  
                        // let last_msg_time =  window.localStorage.setItem('last_msg_time', message.time)
                        localStorage.setItem('last_msg_time',"message")
                    }  

                    //setting vizz_id into localstorage because cookie of vizz_id is not storing
                   // this.leave_chat_text = 'Leave Chat';
                   this.leave_chat_text = 'Leave Chat';
                    if(localStorage.getItem('vizz_id') === null ){
                      localStorage.setItem('vizz_id',res._id);
                    }
                    this.fadeInOut()
                    this.chatService.customerInitiate(this.email);
                    
                }
              // }
            }
            else {
              this.count--
              Swal.fire({
                title: 'Something not fine there...',
                icon: 'error'
              })
              console.log(res);
            }
          }, err => {
            console.log(err);
          });
        //  }
        // else {
        //   this.count--
        //   this.headMessage = 'Enter valid email address'
        // }
      }
    }
  
  }
  sendMessages(text: any, fileType?: string, fileLink?: string, fileName?: string) {

    this.count++
    if(this.count == 1){
      if (text == ''){
        text="Question \n"
      }
    
    if (text) {
      if (!this.firstTimeUser) {
        var newtext = text.replace("\n", '');
        var actualtext = newtext.replace(/\s/g,'')
        if (text != '' && actualtext.length > 0 &&typeof text != 'undefined'){ 
          var message: Message = {
            sender: "customer",
            message: text,
            time: new Date(),
            rating: this.rating,
            comment:  this.newComment,
            fileType: fileType || '',
            fileLink: fileLink || '',
            fileName: fileName || '',
            saveId:''
          }
        // }
       
        // this.count === 0;
        this.messageText = ''

        var data = {
          project_id: this.project_Id, // It will emit the event to all agents joined the project in future can change it to only assigned agent so only he can listen
          chat_id: this.vizz_id,
  
        }
        this.api.saveMessage(message, this.vizz_id).subscribe(res => {
          // let last_msg_time =  localStorage.getItem('last_msg_time')
          // if (this.agentStatus =="offline" && last_msg_time == null){
          //     let agent =   {
          //           "sender": "Agent",
          //           "agentName": "offline",
          //           "message": "Agent is Offline Right Now. Please describe the issue . We Contact with you as soon as possible ",
          //           "time": "",
          //           "fileType": "",
          //           "fileLink": "",
          //           "fileName": ""
          //       }
          //       this.pushMessage(agent)
          //      // let last_msg_time =  window.localStorage.setItem('last_msg_time', message.time)
          //      localStorage.setItem('last_msg_time',"message")
          //  }   
          if(res.hasOwnProperty('success') || res.hasOwnProperty('sender')){
            if(!res.success && !res.sender){
              Swal.fire({
                title: res.message || 'Something not fine...',
                icon: 'error'
              })
            }
            else {
              this.chatService.chatUpdate(data)
              this.chatService.sendMessage(message, this.vizz_id)
            }
          }
          else {
            Swal.fire({
              title: 'Something not fine there...',
              icon: 'error'
            })
          }

        });
      }
      }
      else {
        // if (EmailValidator.validate(this.email)) {
          this.data = {
            name: this.name,
            email: this.email,
            message: (this.message == '') ? this.firstMessage : this.message,
            agents: this.agentStatus,
            rating: this.rating,
            fileType: fileType || '',
            fileLink: fileLink || '',
            fileName: fileName || ''
          }
          this.transcriptEmail = this.email
          this.api.chat(this.data, this.project_Id, this.project_name,this.company_id).subscribe(res => {
            var res = res.data;
            //if(res.hasOwnProperty('success')){
               if(res.hasOwnProperty('success') || res.hasOwnProperty('customer_email')){
            //    if(!res.success ){
            // //    if(!res.success && !res.customer_email){
            //     Swal.fire({
            //       title: res.message || 'Something not fine...',
            //       icon: 'error'
            //     })
            //   }
            //   else{
                if (this.agentStatus == "online" ) {
                  this.chatTaken = false;
                  this.animate = true;
                  // this.topic = this.message
                  this.firstTimeUser = false
                  this.vizz_id = res._id
              
                  this.chatService.joinRoom(res._id)

                  setTimeout(() => {
                    res.messages.forEach(message => {
                      this.pushMessage(message)
                    });
                  }, 50);

                  //setting vizz_id into localstorage because cookie of vizz_id is not storing
                  this.leave_chat_text = 'Leave Chat';

                  if(localStorage.getItem('vizz_id') === null ){
                    localStorage.setItem('vizz_id',res._id);
                  }
                  this.fadeInOut()
                  this.chatService.customerInitiate(this.email);
                  
                }
                else {
                    this.headMessage = 'Thanks we received your message will get back to you soon..'
                    this.chatTaken = false;
                    this.animate = true;
                    // this.topic = this.message
                    this.firstTimeUser = false
                    this.vizz_id = res._id
                    this.chatService.joinRoom(res._id)
                    setTimeout(() => {
                      res.messages.forEach(message => {
                        this.pushMessage(message)
                      });
                    }, 50);
                    let last_msg_time =  localStorage.getItem('last_msg_time')
                    if (this.agentStatus =="offline" && last_msg_time == null){
                        let agent =   {
                              "sender": "Agent",
                              "agentName": "offline",
                              "message": "OK , We contact with you as soon as possible.  ",
                              "time": new Date(),
                              "fileType": "",
                              "fileLink": "",
                              "fileName": ""
                          }
                          setTimeout(() => {   
                              this.pushMessage(agent)
                          }, 50);
                  
                        // let last_msg_time =  window.localStorage.setItem('last_msg_time', message.time)
                        localStorage.setItem('last_msg_time',"message")
                    }  

                    //setting vizz_id into localstorage because cookie of vizz_id is not storing
                   // this.leave_chat_text = 'Leave Chat';
                   this.leave_chat_text = 'Leave Chat';
                    if(localStorage.getItem('vizz_id') === null ){
                      localStorage.setItem('vizz_id',res._id);
                    }
                    this.fadeInOut()
                    this.chatService.customerInitiate(this.email);
                    
                }
              // }
            }
            else {
              console.log("I am here")
              this.count--
              Swal.fire({
                title: 'Something not fine there...',
                icon: 'error'
              })
              console.log(res);
            }
          }, err => {
            console.log(err);
          });
        //  }
        // else {
        //   this.count--
        //   this.headMessage = 'Enter valid email address'
        // }
      }
    }
  }
  }

  leftChat() {
    // this.name = null
    // this.email = null
    this.message = null

    var data = {
      project_id: this.project_Id,
      chat_id: this.vizz_id,
      change_status: true
    }

    this.cookieService.delete('vizz_id', '/')
    this.firstTimeUser = true
    this.chatService.removeCustomer(data)

    this.VCR.remove()
    this.messageText = null
    this.displayLeave = false
    this.displayOptions = false


    if (this.agentStatus == 'online'){
      this.displayRating = true
      this.outSide = false
      //reset rate chat variables
      this.boolean=false;
      this.boolean1=false;
      this.showcomment=true;
      this.htmlStr='Please rate this chat';
  }
   // window.localStorage.getItem{'get'}
 

  // localStorage.clear("vizz_id");

    window.localStorage.removeItem('vizz_id');
    window.localStorage.removeItem('selectedClientId');
    if(this.agentStatus == 'offline'){

      
      localStorage.clear();
      setTimeout(()=>{ window.location.reload(); });
    }
  }

  pushMessage(message) {
    try{
      if(!message){ }
      else{
      

        let componentFactory = this.CFR.resolveComponentFactory(ChatboxmessageComponent);// Create a <chat-box-message> through component factory dynamically
        let componentRef: ComponentRef<ChatboxmessageComponent> = this.VCR.createComponent(componentFactory); // Store the reference fo the component
        let currentComponent = componentRef.instance;                         //Get the inner atributes of the newly created component through instance of the reference
        currentComponent.message = message;                                   // Set the inner variable message of <chat-box-message> component
   
        if (message.sender == 'Agent') {
        if (message.agentName !== undefined){
          currentComponent.user = message.agentName
        } 
          currentComponent.messageColor =  "lightgrey"
        } else {
          currentComponent.user = this.name
          currentComponent.messageColor =  this.primaryColor
          currentComponent.messageTextColor = this.primaryColor
        }
        // if(message.agentName =="vizzBot"){
        // }else{
        //   currentComponent.selfRef = currentComponent; 
        // }
        // Get the variable selfRef inside of newly created <chat-box-message> to store it's own reference
        this.messagesReferences.push(componentRef);
      }
    }
    catch (e){
      console.log('Exception during push message..',e);
    }
    // Push the reference of newly created <chat-box-message> in the array of reference in this module
  }

  updatePushMessage(message,time) {
    try{
      let componentFactory = this.CFR.resolveComponentFactory(ChatboxmessageComponent);// Create a <chat-box-message> through component factory dynamically
      let componentRef: ComponentRef<ChatboxmessageComponent> = this.VCR.createComponent(componentFactory); // Store the reference fo the component
      let currentComponent = componentRef.instance;                         //Get the inner atributes of the newly created component through instance of the reference
      currentComponent.updatemessage = {
        message: message.message,
        time: message.time,
        sender: message.sender
      };

      if (message.sender == 'Agent') {
        currentComponent.user = this.assignedAgent.name
      } else {
        currentComponent.user = this.name
      }

      currentComponent.messageColor = this.primaryColor
      currentComponent.selfRef = currentComponent;                          // Get the variable selfRef inside of newly created <chat-box-message> to store it's own reference
      this.messagesReferences.push(componentRef);                           // Push the reference of newly created <chat-box-message> in the array of reference in this module

    }
    catch (e){
      console.log('exception during updatePushMessage',e);
    }
  }

  playAudio() {
    var audio = new Audio();
    audio.src = "/assets/sounds/longexpected.mp3";
     audio.load();
    audio.play();
  }

  toggleOptions() {
    if (this.displayOptions) {
      this.displayOptions = false
    } else {
      this.displayOptions = true
      this.outSide = false
    }
  }

  toggleTranscript() {

    if (this.displayEmail) {
      this.displayEmail = false
    } else {
      this.displayOptions = false
      this.displayEmail = true
      this.outSide = false
    }
  }

  sendTranscript(download: boolean) {


    if (!this.firstTimeUser) {
      let data = {
        email: this.transcriptEmail,
        chat_id: this.vizz_id,
        download: download
      }

      this.api.sendTranscript(data).subscribe((res) => {
        let httpSuccess = checkForAPIFailResponse(res);
        if(httpSuccess){
          if (download) {
            var data = '';
            res.forEach((message) => {
              if (message.sender == 'customer') {
                data += 'You   : ' + message.message
              } else {
                data += 'Agent : ' + message.message
              }
            })

            const blob = new Blob([data], { type: 'plain/text' });
            const url = window.URL.createObjectURL(blob);
            const link = this.downloadZipLink.nativeElement;
            link.href = url;
            link.download = 'chat.txt';
            link.click();
            window.URL.revokeObjectURL(url);
          }
        }
      })
    }


  }

  toggleLeave() {
    if (!this.firstTimeUser) {
      if (this.displayLeave) {
        this.displayLeave = false
      } else {
        this.displayOptions = false
        this.displayLeave = true
        this.outSide = false
      }
    }
  }

  toggleRating() {

    if (this.displayRating) {
      this.displayRating = false
    } else {
      this.displayOptions = false
      this.displayRating = true
      this.outSide = false
    }
  }

  clickedOutside(event: Object) {
    if (this.outSide) {
      if (event && event['value'] === true) {
        this.displayEmail = false
        this.displayOptions = false
        this.displayLeave = false
        this.displayRating = false
      }
    } else {
      this.outSide = true
    }
  }

  clickedOutside1(event: Object){
    this.displayRating = false
  }

  fadeInOut = () => {

    if (this.animate) {

      if (this.animationState == 'close') {
        this.animationState = 'open'
      } else {
        this.animationState = 'close'
      }

      setTimeout(() => {
        this.fadeInOut()
      }, 800);

    }

  }

  customerTyping() {
    this.chatService.typing({ roomno: this.vizz_id, user: 'customer' })
  }

  minizifrm() {
    // let ifrmclsouter = document.getElementById("Vchat_modul");
    
    // if(ifrmclsouter) {
    //   ifrmclsouter.classList.toggle("chat_modul");
    // }
  }

  /*Dropzone*/
  onSelect(event) {
    this.files.push(...event.addedFiles);
    if(this.files.length>0){
      for(let i=1;i<this.files.length;i++){
        this.files.splice(i,1);
      }
    }
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  uploadAttachment(){
    let formData = new FormData();
    let data = {
      uploadedby:"customer",
    };

    this.files.forEach(( e ) => {
      formData.append('chat_attachment',e, e.name);
    });

    formData.append('data',JSON.stringify(data));

    this.api.sendChatAttachment(formData).subscribe(res=>{
      let httpSuccess = checkForAPIFailResponse(res);
      if(httpSuccess){
        if(res.success){
          this.showFileUploadModal = false;
          this.files = [];
          this.sendMessage("file-share",res.mimeType,`chat_attachments/${res.id}`,res.originalname)
        }
      }
    })
  }
  /*End*/

}
