import { Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { ApiService } from '../api.service';
import { NgFlashMessageService } from 'ng-flash-messages';
import { ActivatedRoute } from '@angular/router';
import { checkForAPIFailResponse } from '../../assets/js/utils';

@Component({
  selector: 'app-agent-list',
  templateUrl: './agent-list.component.html',
  styleUrls: ['./agent-list.component.css']
})

export class AgentListComponent implements OnInit {
  isAgent: boolean = false;
  today = new Date();
  yesterday = new Date(Date.now() - 86400000);
  filterlist;
  agents;
  componentsReferences = [];
  @ViewChild('viewContainerRef', { read: typeof ViewContainerRef , static: true }) VCR: ViewContainerRef;

  dateModel = [
    { id: 1, name: "today", date: this.today },
    { id: 2, name: "yesterday", date: this.yesterday },
    { id: 3, name: "last week", date: this.today },

  ];
  agent: any;
  filteredData: any;
  agent_name: string;
  showEditAgentModel: boolean = false;

  /*Edit Agent Props*/
  agentName: string;
  agentNewPassword: string;
  agentDescription: string;
  agentEmail: string;
  agentStatus : number;
  selectedAgent : any;
  /*END*/

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private ngFlashMessageService: NgFlashMessageService,
    private CFR: ComponentFactoryResolver, )
  { }

  ngOnInit() {
    console.log('Working')
    this.isAgent = localStorage.getItem('user_role')=='agent'?true:false;
    let id;
    if(this.isAgent){
      id = localStorage.getItem('company_Id')
    }
    else{
      id = localStorage.getItem('user')
    }
    this.api.Agent(id).subscribe(res => {
      let httpSuccess = checkForAPIFailResponse(res);
      if(httpSuccess){
        this.agent = res;
        this.filteredData = JSON.parse(JSON.stringify(this.agent));
        this.filterlist = this.agent
      }
    }, err => {
      console.log(err);
    });
  }

  nameFilter(){
    let searchValue = (document.getElementById('_filter_name') as HTMLInputElement).value;
    if(searchValue){
      this.filteredData = this.agent.filter(e=>{
        return e.user_name.toLowerCase().includes(searchValue)
      })
    }
    else {
      this.filteredData = this.agent;
    }
  }

  getValue(d) {
    if (d.name === "today") {
      this.agent = this.filterlist
      this.agent = this.agent.filter(data => new Date(data.date).getDate() === new Date(d.date).getDate())
    }
    if (d.name === "yesterday") {
      this.agent = this.filterlist
      this.agent = this.agent.filter(data => new Date(data.date).getDate() === new Date(d.date).getDate())
    }
    if (d.name === "last week") {
      this.agent = this.filterlist
      this.agent = this.agent.filter(data => new Date(data.date).getDate() <= new Date(d.date).getDate() + 7)
    }
  }

  showEditAgentModal (selectedAgent) {
    this.selectedAgent = selectedAgent;
    this.showEditAgentModel = true;

    this.agentName = selectedAgent.name || '';
    this.agentEmail = selectedAgent.agent_email || '';
    this.agentDescription = selectedAgent.description || '';
    this.agentStatus = selectedAgent.status || '';
  }

  editAgent() {
    let data = {
      name: this.agentName,
      newPassword: this.agentNewPassword,
      description:this.agentDescription,
      agent_email:this.agentEmail,
      status: this.agentStatus
    }

    this.api.editagent(data, this.selectedAgent._id).subscribe((response) => {
      let httpSuccess = checkForAPIFailResponse(response);
      if(httpSuccess){
        this.showEditAgentModel = false;
        let messages = [response.message]
        if (response.success) {
          this.ngFlashMessageService.showFlashMessage({
            messages: messages,
            dismissible: true,
            timeout: 5000,
            type: 'success'
          });

          this.selectedAgent.name = this.agentName;
          this.selectedAgent.status = this.agentStatus;
          this.selectedAgent.agent_email = this.agentEmail;
          this.selectedAgent.description = this.agentDescription;
        }
      }
    })
  }

}
