import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { checkForAPIFailResponse } from '../../assets/js/utils';
import { ThisReceiver, ThrowStmt } from '@angular/compiler';
import * as CanvasJS from '../../assets/img/canvasjs.min';
//import * as Highcharts from 'highcharts';
// import * as Highcharts from 'highcharts';
//import * as Highcharts from 'highcharts/highstock'
@Component({
  selector: 'app-agent-chatgraph',
  templateUrl: './agent-chatgraph.component.html',
  styleUrls: ['./agent-chatgraph.component.css']
})
export class AgentChatgraphComponent implements OnInit {
  // Highcharts: typeof Highcharts = Highcharts;
  customer_name:string;
  customer_email:string;
  company_id:string;
  filter_agent_name:string;
  customer_status:string;
  project_name: string;
  project_id:string;
  agentid: string;
  date_from:string;
  date_to:string
  selected_filters:any;
  project: any;
  agent:any;
  data:any;
  agent_name: any
  open_chats: any;
  close_chats: any;
  offline_chats: any;
  taken_chats: any;
  missed_chats:any;


  constructor(
    private api:ApiService,
  ) { 

  }

  ngOnInit(): void {
    var open_chats =this.open_chats
    this.company_id = localStorage.getItem('user');
    if(this.company_id){
      this.selected_filters ={company_Id:this.company_id}
    }
    this.getMoreChatGraph(this.selected_filters)
    // this.agentsPresenceChatsChart()
    // setTimeout(() => {
    //   // var chart = Highcharts.chart("container", this.chartOptions );
    //   this.agentsPresenceChatsChart()
    // }, 1000);
  }

  filters(){

    let filter_array={
     
      company_Id:this.company_id,
      project_id:this.project_id,
      filter_agent_name:this.filter_agent_name,
      customer_status:this.customer_status,
      project_name: this.project_name,
      filter_agentid:this.agentid,
      date_from:this.date_from,
      date_to:this.date_to
    };
    this.selected_filters=filter_array;
    this.getMoreChatGraph(this.selected_filters)

    localStorage.setItem("chats_filter_array", JSON.stringify(filter_array));
  }
  getMoreChatGraph(filter){
    this.api.getAgentChatGraph(filter).subscribe((messages)=>{

      let httpSuccess = checkForAPIFailResponse(messages);
      if(httpSuccess){
      
        this.agent_name = messages.agent_name;
        this.close_chats = messages.close_chats;
        this.offline_chats = messages.offline_chats;
        this.taken_chats = messages.taken_chats;
        this.missed_chats = messages.missed_chats;

        this.open_chats = messages.open_chats;
    
        // this.data = messages.data;
        // console.log("here",this.data[0])
        this.project = messages.project;
          setTimeout(() => {
      // var chart = Highcharts.chart("container", this.chartOptions );
      this.agentsPresenceChatsChart()
    }, 1000);
;
      }

    })
  }

  // chartOptions: Highcharts.Options = {

  //     chart: {
  //       type: 'column'
  //     },
  //     title: {
  //       text: 'Monthly Chat of Agent'
  //     },
     
  //     xAxis: {
  //       categories: ["inam","zeeshan","zain"],
  //       crosshair: true
  //     },
  //     yAxis: {
  //       min: 0,
  //       title: {
  //         text: 'Number'
  //       }
  //     },
  //     tooltip: {
  //       headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
  //       pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
  //         '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
  //       footerFormat: '</table>',
  //       shared: true,
  //       useHTML: true
  //     },
  //     plotOptions: {
  //       column: {
  //         pointPadding: 0.2,
  //         borderWidth: 0
  //       }
  //     },
  //     series: [
  //       {
  //       type: 'column',
  //       name: 'Open',
  //       data:  this.open_chats
    
  //     },
  //      {
  //       type: 'column',
  //       name: 'Close',
  //       data:  this.close_chats
    
  //     }, {
  //       type: 'column',
  //       name: 'Taken',
  //       data:  this.taken_chats
    
  //     }, {
  //       type: 'column',
  //       name: 'Missed',
  //       data:  this.missed_chats
    
  //     }]
  //   }
  agentsPresenceChatsChart(){
  var chart = new CanvasJS.Chart("chartContainers", {
    animationEnabled: true,
    title:{
      text: " Chat Records Of Agents"
    },
    axisY: {
      title: "Chat Record",
      includeZero: true
    },
    legend: {
      cursor:"pointer",
      itemclick : toggleDataSeries
    },
    toolTip: {
      shared: true,
      content: toolTipFormatter
    },
    data: [
    //   {
    //   type: "column",
    //   showInLegend: true,
    //   name: "Offline",
    //   color: "gold",
    //   dataPoints: this.offline_chats
    // },
    {
      type: "column",
      showInLegend: true,
      name: "Close",
      color: "silver",
      dataPoints: this.close_chats
    },
    {
      type: "column",
      showInLegend: true,
      name: "Missed",
      color: "pink",
      dataPoints: this.missed_chats
    },  {
      type: "column",
      showInLegend: true,
      name: "Taken",
      color: "orange",
      dataPoints: this.taken_chats
    }]
  });
  chart.render();

  function toolTipFormatter(e) {
    var str = "";
    var total = 0 ;
    var str3;
    var str2 ;
    for (var i = 0; i < e.entries.length; i++){
      var str1 = "<span style= \"color:"+e.entries[i].dataSeries.color + "\">" + e.entries[i].dataSeries.name + "</span>: <strong>"+  e.entries[i].dataPoint.y + "</strong> <br/>" ;
      total = e.entries[i].dataPoint.y + total;
      str = str.concat(str1);
    }
    str2 = "<strong>" + e.entries[0].dataPoint.label + "</strong> <br/>";
    str3 = "<span style = \"color:Tomato\">Total: </span><strong>" + total + "</strong><br/>";
    return (str2.concat(str)).concat(str3);
  }
  
  function toggleDataSeries(e) {
    if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    }
    else {
      e.dataSeries.visible = true;
    }
    chart.render();
  }
  }
}
