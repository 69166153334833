import { Component } from '@angular/core';
import * as CanvasJS from '../../assets/img/canvasjs.min';
import {ApiService} from '../api.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})

export class ReportComponent {
  report:any
  isReport:boolean=false
  minDate:any
  maxDate:any
  visitorPercentage:any
  newReport: void;

  constructor(private api:ApiService){
    this.api.getReportRecord().subscribe(res=>{
      this.report=res
      this.isReport=false;
      this.visitorPercentage=this.report.totalVisitors;

      this.generalStatsChart();
      this.agentsPresenceChart();
      this.agentsPresenceStatsChart();
  
    })
  }

  generalStatsChart(){
    let chart = new CanvasJS.Chart("general_stats_chart",{
      title: {
        text: "General Stats",
        fontSize:20,
        fontFamily:'Helvetica'
      },
      animationEnabled: true,
      // data: [{
      //   cursor: "pointer",
      //   explodeOnClick: false,
      //   innerRadius: "60%",
      //   legendMarkerType: "square",
      //   name: "New vs Returning Visitors",
      //   radius: "100%",
      //   showInLegend: true,
      //   startAngle: 90,
      //   type: "doughnut",
      //   dataPoints: [{
      //     // y:report.totalVisitors,
      //     y:this.report.totalChat,
      //     exploded: true,
      //     name: "Total Visitors",
      //     color: "#11b3ba"
      //   }, {
      //     y: this.report.totalAgents,
      //     name: "Total Agents",
      //     color: " #ffbb78"
      //   }]
      // }]
      data: [{
        type: "pyramid",
        // yValueFormatString: "#\"%\"",
        indexLabelFontColor: "black",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {y}",
        //reversed: true, // Reverses the pyramid
        dataPoints: [
          { y: this.report.totalVisitors, label: "Total Vistors" },
          { y: this.report.totalAgents, label: "Total Agents" },
        
        ]
      }]
    
    });
    chart.render();
  }

  agentsPresenceChart(){
    let chart = new CanvasJS.Chart('active_agents_chart', {
      title: {
        text: "Agents Presence",
        fontSize:20,
        fontFamily:'Helvetica'
      },
      animationEnabled: true,
      data: [
          {
            cursor: "pointer",
            explodeOnClick: false,
            innerRadius: "60%",
            legendMarkerType: "square",
            name: "New vs Returning Visitors",
            radius: "100%",
            showInLegend: true,
            indexLabel: "{label} - {y}",
            startAngle: 90,
            type: "doughnut",
            dataPoints: [
        { y: this.report.currentOnlineAgents, exploded: true, name: "Online Agents", label:"Online Agents", color: "#1b8320" },
        { y: this.report.totalAgents - this.report.currentOnlineAgents, exploded: true, name: "Offline Agents", label:"Offline Agents", color: "#E7823A" }
      ]}]
    });
    chart.render();
  }

  agentsPresenceStatsChart(){
    let chart = new CanvasJS.Chart('active_agents_stats_chart', {
      title: {
        text: "Visitors Stats",
        fontSize:20,
        fontFamily:'Helvetica'
      },
      animationEnabled: true,
      data: [
        {
          cursor: "pointer",
          explodeOnClick: false,
          innerRadius: "60%",
          legendMarkerType: "square",
          name: "New vs Returning Visitors",
          radius: "100%",
          showInLegend: true,
          indexLabel: "{label} - {y}",
          startAngle: 90,
          type: "pie",
          dataPoints: [
            { y: this.report.todayVisitors, exploded: true, name: "Today Visitors",label: "Today Visitors", color: "#3ae1e7" },
            { y: this.report.yesterdayVisitors, exploded: true, name: "Yesterday", label:"Yesterday",color: "#e568d9" },
            { y: this.report.lastWeekVisitors, exploded: true, name: "Last Weeks", label:"Last Weeks",color: "#ec3a1f" },
          ]}]
    });
    chart.render();
  }
  
  dateValue(minDate,maxDate){

    this.api.getNewReport(minDate,maxDate).subscribe(x=>{
      this.newReport=x
      this.report=this.newReport
      var piechart = new CanvasJS.Chart("pieChart",{
        backgroundColor: "",
        colorSet: "colorSet2",
        title: {
          text: "",
          fontFamily: "Verdana",
          fontSize: 35,
          fontWeight: "bold",
        },
        animationEnabled: true,
        data: [{
          indexLabelFontSize: 25,
          indexLabelFontFamily: "Monospace",
          indexLabelFontColor: "#878282",
          indexLabelLineColor: "orange",
          indexLabelPlacement: "outside",
          type: "pie",
          showInLegend: false,
          toolTipContent: "<strong>#percent%</strong>",
          indexLabel: "{name} - #percent%",
          dataPoints: [{
            y:x.totalVisitors,
            exploded: true,
            name: "Total Visitors",
            color: "#11b3ba"
          }, {
            y: x.TotalChat,
            name: "Total Chats",
            color: "#ff7f0e"
          }, {
            y: x.TotalAgent,
            name: "Total Agents",
            color: " #ffbb78"
          }

          ]
        }]
      });



      piechart.render();


    })
  }

}
