import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { NgFlashMessageService } from 'ng-flash-messages';
import { first } from 'rxjs/operators';
import { ServicesService } from '../services.service';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { checkForAPIFailResponse } from '../../assets/js/utils';

@Component({
  selector: 'app-agent-login',
  templateUrl: './agent-login.component.html',
  styleUrls: ['./agent-login.component.css']
})
export class AgentLoginComponent implements OnInit {
  browserName = '';
  @Input() Agent: any;
  name: String;
  password: String;
  email: String;
  data: any;
  invalidLogin: boolean = false;
  messages = [];

  constructor(
    private api: ApiService,
    private router: Router,
    private ngFlashMessageService: NgFlashMessageService,
    private Data:ServicesService
  ) {
    try{
      const urlParams = new URLSearchParams(window.location.search);
      const re_initiated = urlParams.get('re_initiated');
      if(!re_initiated){
        window.location.href = window.location.href+'?re_initiated=true'
      }
    }
    catch (e){
      console.log(e);
    }
  }

  ngOnInit() {
    if (this.api.agentlogIn()) {
      this.router.navigate(['/agent-dashboard'])
    }
    this.browserName = this.detectBrowserName();
    this.setLoginPageDesign();
  }
  
  detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
  loginAgent() {
    if(localStorage.getItem('token')||localStorage.getItem('agent-token')){
      let mic=(localStorage.getItem('token'))?'Admin Account':'Agent Account';
      this.messages[0] = 'You are aleady LoggedIn from '+mic;
      this.ngFlashMessageService.showFlashMessage({
        messages: this.messages,
        dismissible: true,
        timeout: 3000,
        type: 'danger'
      });

      this.router.navigate(['/agent-login']);
    }else{
      const user = {
        name: this.name,
        password: this.password,
        email: this.email,
        browserName:this.browserName
      }
      this.Agent = user.name;

      this.api.authenticateAgent(user).pipe(first()).subscribe(data => {
        this.messages[0] = data.message
        if (data.success) {
          this.api.storeAgentData(data.token, data.user.name, data.user.id, data.user.user_role,data);
          this.ngFlashMessageService.showFlashMessage({
            messages: this.messages,
            dismissible: true,
            timeout: 3000,
            type: 'success'
          });
          this.router.navigate(['/agent-googleauth']);
       //   this.unsetLoginPageDesign();
        } else {

          Swal.fire({
            title: 'Login Failed',
            text: this.messages[0] || 'Something not fine',
            icon: 'error'
          })
        }
      });
    }
  }

  onLogoutClick() {
    localStorage.clear();
    this.router.navigate(['/agent-login']);
    return false;
  }

  setLoginPageDesign() {
    document.body.style.backgroundRepeat  = "no-repeat";
    document.body.style.backgroundImage = "linear-gradient(#b8dbdc, #3cb9bc)";
    document.body.style.height = "100%";
    document.getElementById('main-html-tag').style.height = "100%";
    localStorage.clear();
  }

  unsetLoginPageDesign() {
    document.body.style.backgroundRepeat  = "unset";
    document.body.style.backgroundImage = "unset";
    document.body.style.height = "unset";
    document.getElementById('main-html-tag').style.height = "unset";
  }
}
