import {
  Component,
  OnInit,
  Input,
  ViewContainerRef,
  ViewChild,
  EventEmitter,
  ComponentRef,
  ComponentFactoryResolver,
  Optional
} from '@angular/core';
import { MessageComponent } from '../message/message.component';
import { ApiService } from '../api.service';
import 'rxjs/add/operator/map';
import { ChatService } from '../chat.service';
import { trigger, state, style, animate, transition } from '@angular/animations'
import { ServicesService } from '../services.service';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { checkForAPIFailResponse } from '../../assets/js/utils';
import {Router} from '@angular/router';

interface Message {
  sender: string;
  message: string;
  agentName: string;
  time: Date;
  fileType: string;
  fileLink:string;
  fileName:string;
}

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),
      state('open', style({ opacity: 1 })),
      state('close', style({ opacity: 0 })),

      transition('close => open', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      transition('open => close', animate(600, style({ opacity: 0 }))),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ])
  ]

})
export class MessagesComponent implements OnInit {
  @ViewChild('viewContainerRef', { read: ViewContainerRef , static: true}) VCR: ViewContainerRef;

  @Input() someEvent = new EventEmitter<string>();
  public selfRef: MessagesComponent;
  componentsReferences = [];
  messages: any;
  messageText: any;
  ClientId: any;
  public id;
  message_Id;
  typingCounter: number=0;
  disableTextArea: boolean;
  customerTyping: boolean;
  animate: boolean = false;
  animationState: string = 'open';
  customerName: string;
  history: boolean = false
  child;
  updateMessage: any;
  boolean: boolean = false;
  update_message
  Id:number=0;
  count:number=0;
  showFileUploadModal: boolean = false;
  files: File[] = [];
  showSendButton: boolean = false;

  constructor(
    private api: ApiService,
    private CFR: ComponentFactoryResolver,
    private chatService: ChatService,
    private servicesData: ServicesService,
    private router: Router,
  ) {
    this.servicesData.currentMessage.subscribe((data) => {
    this.updateMessage = data;
      this.boolean = this.updateMessage.status,
        this.messageText = this.updateMessage.message;
    })
  }

  ngOnInit() {
    if (this.ClientId != null && typeof this.ClientId != 'undefined' && !this.history) {
      this.chatService.joinRoom(this.ClientId)
    }

    this.chatService.getMessages().subscribe((message) => {
      this.customerTyping = false;

      this.createMessage(message)
      if (message.sender != 'Agent') {
         let lastmsg_time=localStorage.getItem('last_msg_time');
         if(!lastmsg_time||lastmsg_time!=message.time){
           localStorage.setItem('last_msg_time', message.time);
           this.playAudio()
           //TODO replaced local browser notification with onesignal
           // this.chatService.showDesktopNotification(message.sender,message.message,message.time);
         }

        //this.playAudio()
        //this.chatService.showDesktopNotification(message.sender,message.message,message.time);
      }
    });

    this.chatService.recievedTyping().subscribe((data) => {
      if (data.user == 'customer' && this.ClientId == data.roomno) {
        this.customerTyping = true;
        this.animate = true;
        setTimeout(()=>{
          this.animate = false;
        },2000)
      }
    })

    if(this.ClientId){
      localStorage.setItem('selectedClientId',this.ClientId)
    }

    let userRole = localStorage.getItem("user_role");
    if (this.router && this.router.url == '/history') {
      this.showSendButton = false
    }
    else if(userRole == 'company'){
      this.showSendButton = false;
    }
    else{
      this.showSendButton = true
    }
  }

  playAudio() {
    var audio = new Audio();
    audio.src = "/assets/sounds/longexpected.mp3";
     audio.load();
    audio.play();
  }

  getMessage(data: any) {
    this.VCR.remove();
    if (!data.assigned) {
      this.api.assignAgent(data)
      this.chatService.assignAgent(data)
    }
    this.disableTextArea = false;
    this.api.get_message(data.chat_id).subscribe(res => {
      let httpSuccess = checkForAPIFailResponse(res);
      if(httpSuccess){
        let total_message=res.messages.length;
        let count=1;
        res.messages.forEach(message => {
          this.id=message._id
          this.createMessage(message)
          if(message&&count==total_message){
            //this.playAudio()
            //this.chatService.showDesktopNotification(message.sender,message.message,message.time);
          }
          this.servicesData.getRatingMessage(message,data.chat_id)
          count++;
        });
      }
    }, err => {
      console.log(err);
    });
  }

  sendMessage(text: string, fileType?: string, fileLink?: string, fileName?: string) {
  
    var newtext = text.replace("\n", '');
    var actualtext = newtext.replace(/\s/g,'')

    if (text != '' && actualtext.length > 0 &&typeof text != 'undefined') {
      const message: Message = {
        sender: "Agent",
        agentName:localStorage.getItem("agent"),
        message: text,
        time: new Date(),
        fileType: fileType || '',
        fileLink: fileLink || '',
        fileName: fileName || ''
      }

      this.messageText = ''
      this.chatService.sendMessage(message, this.ClientId)
      this.api.saveMessage(message, this.ClientId).subscribe(res => {
        if(res.hasOwnProperty('success') || res.hasOwnProperty('sender')){      
          if(!res.success && !res.sender){
            Swal.fire({
              title: res.message || 'Something not fine...',
              icon: 'error'
            })
          }
        }
        else {
          Swal.fire({
            title: 'Something not fine there...',
            icon: 'error'
          })
        }
      });
    }
  }

  createMessage(message) {
    let componentFactory = this.CFR.resolveComponentFactory(MessageComponent);
    let componentRef: ComponentRef<MessageComponent> = this.VCR.createComponent(componentFactory);
    let currentComponent = componentRef.instance;
    currentComponent.message = message;
    currentComponent.customerName = this.customerName
    currentComponent.selfRef = currentComponent;
    this.componentsReferences.push(componentRef);

  }

  close(chat_Id) {
    this.api.closeChat(chat_Id);
  }

  fadeInOut = () => {
    if (this.animate) {
      if (this.animationState == 'close') {
        this.animationState = 'open'
      } else {
        this.animationState = 'close'
      }
      setTimeout(() => {
        this.fadeInOut()
      }, 800);
    }
  }

  typing() {
    this.typingCounter++
    if ((this.typingCounter % 10) === 0 ){
      this.chatService.typing({ roomno: this.ClientId, user: 'agent' })
    }
   
  }

  getChildValue(data) {
    if (data) {
      this.child = data;
    }
  }


  /*Dropzone*/
  onSelect(event) {
    this.files.push(...event.addedFiles);
    if(this.files.length>0){
      for(let i=1;i<this.files.length;i++){
        this.files.splice(i,1);
      }
    }
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  uploadAttachment(){
    let formData = new FormData();
    let data = {
      uploadedby:"agent",
      agentId:localStorage.getItem("agentid")
    };

    this.files.forEach(( e ) => {
      formData.append('chat_attachment',e, e.name);
    });

    formData.append('data',JSON.stringify(data));

    this.api.sendChatAttachment(formData).subscribe(res=>{
      let httpSuccess = checkForAPIFailResponse(res);
      if(httpSuccess){
        if(res.success){
          this.showFileUploadModal = false;
          this.files = [];
          this.sendMessage("file-share",res.mimeType,`chat_attachments/${res.id}`,res.originalname)
        }
      }
    })
  }
  /*End*/
}



