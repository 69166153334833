import { Component ,OnInit} from '@angular/core';

import * as CanvasJS from '../../assets/img/canvasjs.min';
//import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-agent-timegraph',
  templateUrl: './agent-timegraph.component.html',
  styleUrls: ['./agent-timegraph.component.css']
})
export class AgentTimegraphComponent implements OnInit{
  //addSymbols: any;

 

  ngOnInit() {
    this.agenttimegraph() 
  }

agenttimegraph(){
 let chart = new CanvasJS.Chart("agnet_time_graph",	{
  title: {
    text: "How long an event occurred for on a given day"
  },
  axisY: {
    minimum: (new Date(2016, 0, 28, 12, 0)).getTime(),            
    interval: (1 * 60 * 60 * 1000),
    labelFormatter: function(e){
      return CanvasJS.formatDate(e.value, "DD - h:mm TT");
    },
    gridThickness: 2
  },
  axisX: {
      interval: 1,
      valueFormatString: "DD-MMM"
    },
  toolTip:{
    contentFormatter: function ( e ) {
      return "<strong>" + e.entries[0].dataPoint.label + "</strong></br> Start: " +  CanvasJS.formatDate(e.entries[0].dataPoint.y[0], "DD - h:mm TT") + "</br>End : " +  CanvasJS.formatDate(e.entries[0].dataPoint.y[1], "DD - h:mm TT");  
    }},

  data: [
    {
      type: "rangeBar",
      indexLabelFontSize: 14,
      dataPoints: [
        { x: new Date(2014,0o3,1), y: [(new Date(2016, 0, 28, 12, 20)).getTime(), (new Date(2016, 0, 28, 13, 0o0)).getTime(),(new Date(2016, 0, 28, 14, 20)).getTime(), (new Date(2016, 0, 28, 15, 0o0)).getTime()] },
        { x: new Date(2014,0o3,2), y: [(new Date(2016, 0, 28, 13, 20)).getTime(), (new Date(2016, 0, 28, 14, 20)).getTime()] },
        { x: new Date(2014,0o3,3), y: [(new Date(2016, 0, 28, 14, 20)).getTime(), (new Date(2016, 0, 28, 15, 0o0)).getTime()] }
      ]
    }
  ]                      
  // title: {
  //   text: "Agent Online Graph"
  // },
  // exportEnabled: true,
  // axisY: {
  //   includeZero: false,
  //   interval: 2,
  //   valueFormatString: "#0.## °C"
  // },
  // axisX: {
  //   interval: 1,
  //   valueFormatString: "DD-MMM"
  // },
  // toolTip: {
  //   content: "{x} </br> Min: {y[0]}, Max: {y[1]}"
  // },
  // data: [
  // {
  //   type: "rangeBar",
  //   yValueFormatString: "#0.## °C",
  //   indexLabel: "{y[#index]}",
  //   indexLabelFontSize: 14,
  //   dataPoints: [
  //     {x: new Date(2014,0o3,1), y:  [ 17,33]},  
  //     {x: new Date(2014,0o3,2), y: [ 18,35]},
  //     {x: new Date(2014,0o3,3), y: [ 18,32]},
  //     {x: new Date(2014,0o3,4), y: [ 18,32]},
  //     {x: new Date(2014,0o3,5), y: [ 20,35]},
  //     {x: new Date(2014,0o3,6), y: [ 20,38]},
  //     {x: new Date(2014,0o3,7), y: [ 21,32]}
  //   ]
  // }
  // ]
});
chart.render();
}

}
