import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { checkForAPIFailResponse } from '../../assets/js/utils';


@Component({
  selector: 'app-agent-attendance',
  templateUrl: './agent-attendance.component.html',
  styleUrls: ['./agent-attendance.component.css']
})
export class AgentAttendanceComponent implements OnInit {
  isAgent: boolean = false;
  customer_name:string;
  browser_name:string;
  company_id: string;
  filter_agentid:string;
  date_from:string;
  date_to:string;
  agentid:string;
  selected_filters:any;
  p:any;
  customerMessages:any;
  filterlist:any;
  pages:any;
  customer:any;
  constructor(
    private api: ApiService,
  ) { 

  }

  ngOnInit() {
    this.isAgent = localStorage.getItem('user_role')=='agent'?true:false;
    this.company_id = (localStorage.getItem('user_role')=='agent')?localStorage.getItem('company_Id'):localStorage.getItem('user');
    if(this.company_id){
      this.selected_filters={company_Id:this.company_id};
    }

    this.getAgentHistory(0,this.selected_filters);
  }
  pageChanged(event){
    this.p = event;
    this.getAgentHistory(event,this.selected_filters);
  }

  filters(){
        
    let filter_array={
      customer_name:this.customer_name,
      browser_name:this.browser_name,
      company_Id:this.company_id,
      date_from:this.date_from,
      date_to:this.date_to
    };
    this.selected_filters=filter_array;
    this.getAgentHistory(this.p,this.selected_filters)

    // localStorage.setItem("chats_filter_array", JSON.stringify(filter_array));

  }

  getAgentHistory(skip, filters){
    this.p = skip;
    this.api.getAgentTimeData(skip,filters).subscribe((messages) => {
      let httpSuccess = checkForAPIFailResponse(messages);
      if(httpSuccess){
        this.customerMessages = messages.record
        this.filterlist = messages.record
        this.pages = messages.data;
      }
    })
  }

}
